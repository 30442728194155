import { useReducer } from 'react'
import { ApiClient } from '@quiqupltd/quiqupjs'
import { loadEnv } from '../configEnv'

interface StateInterface {
  loading: boolean
  successMessage: string
  error: null | string
}

interface ActionInterface {
  separateOrder(): void
}

interface HookInterface {
  separateState: StateInterface
  separateActions: ActionInterface
}
// Constants
enum actions {
  FETCH_INIT = 'FETCH_INIT',
  FETCH_SUCCESS = 'FETCH_SUCCESS',
  FETCH_FAILURE = 'FETCH_FAILURE',
}

const initialState: StateInterface = {
  loading: false,
  successMessage: '',
  error: null,
}

// Reducer
type Action =
  | { type: actions.FETCH_INIT }
  | {
      type: actions.FETCH_SUCCESS
      successMessage: string
    }
  | { type: actions.FETCH_FAILURE; error: null | string }

function reducer(state: StateInterface, action: Action): StateInterface {
  switch (action.type) {
    case actions.FETCH_INIT:
      return { ...state, loading: true }
    case actions.FETCH_SUCCESS:
      return { ...state, loading: false, error: null, successMessage: action.successMessage }
    case actions.FETCH_FAILURE:
      return { ...state, loading: false, error: action.error }
    default:
      return state
  }
}

export function useOrderSeparate(
  orderId: number | undefined,
  uuid: string | undefined,
  internalOrderId: number | undefined,
  missionId: number | string | undefined
): HookInterface {
  const [state, dispatch] = useReducer(reducer, initialState)

  async function separateOrder(): Promise<void> {
    const env = await loadEnv()

    if (!orderId || (!internalOrderId && is_old_mission_id(missionId))) {
      return
    }
    dispatch({ type: actions.FETCH_INIT })
    try {
      if (is_old_mission_id(missionId)) {
        await ApiClient.post({
          path: env.API_URL + `/csr/orders/${internalOrderId}/unpool`,
        })

        const response = await ApiClient.get({
          path: env.EX_CORE_API_URL + `/orders/${orderId}`,
        })

        const { order } = response

        await ApiClient.post({
          path: env.API_URL + `/csr/missions/${order.mission?.id}/events`,
          data: { event: 'cancel' },
        })
      } else {
        await ApiClient.put({
          path: env.EX_CORE_API_URL + `/api/missions/unpool/orders/${uuid}`,
        })
      }
      dispatch({
        type: actions.FETCH_SUCCESS,
        successMessage: `Success! Order ${orderId} have been separated from the mission`,
      })
    } catch (error) {
      const status = (error as any).status
      if (status === undefined) {
        dispatch({
          type: actions.FETCH_SUCCESS,
          successMessage: `Success! Order ${orderId} have been separated from the mission`,
        })
      }
      dispatch({
        type: actions.FETCH_FAILURE,
        error: `Error! Order ${orderId} could not be separated from the mission, `,
      })
    }
  }
  return { separateState: state, separateActions: { separateOrder } }
}
const is_old_mission_id = (missionId: number | string | undefined) => {
  const integerRegex = /^-?\d+$/
  return typeof missionId == 'number' || (missionId && integerRegex.test(missionId))
}
