import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Card as AntCard, Typography, Tag as AntTag } from 'antd'
import noop from 'lodash/noop'
import { ORDER_TYPES } from './BulkActions'
import CopyToClipboard from '../CopyToClipboard'

const { Title } = Typography

interface CardProps {
  boxType: string
}

const Card = styled(AntCard)`
  position: relative;
  ${(p: CardProps) =>
    p.boxType === ORDER_TYPES.RECOGNIZED &&
    `
    min-height: 215px;
  `}
`

const Tag = styled(AntTag)`
  margin-bottom: 10px !important;
`

interface Props {
  title: string
  orders: [string, string?][]
  closable: boolean
  onRemoveOrder: (order: string) => void
  type: ORDER_TYPES
  tagsColour: string
  shouldCopyToClipboard?: boolean
}

const cleanAndFormatOrderIds = (orders: [string, string?][]): string => {
  const cleanOrderIds = orders.map((order) => order[0].split('-')[0])
  return cleanOrderIds.join('\r\n')
}

function OrderBox(props: Props): JSX.Element {
  const clipboardContent = useMemo(() => cleanAndFormatOrderIds(props.orders), [props.orders])

  function createHandleClose(orderId: string) {
    return function handleClose() {
      props.onRemoveOrder(orderId)
    }
  }
  return (
    <div>
      <Title level={3}>{props.title}</Title>
      <Card boxType={props.type}>
        {props.orders.map(([order, count]) => {
          return (
            <Tag color={props.tagsColour} closable={props.closable} key={order} onClose={createHandleClose(order)}>
              {order} {count || ''}
            </Tag>
          )
        })}
        {clipboardContent && <CopyToClipboard content={clipboardContent} />}
      </Card>
    </div>
  )
}

OrderBox.defaultProps = {
  onRemoveOrder: noop,
  shouldCopyToClipboard: false,
}

export default React.memo(OrderBox)
