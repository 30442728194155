import React, { useReducer } from 'react'
import UserInterface from '../types/user.type'
import noop from 'lodash/noop'

export const ALLOWED_ORDER_CREATION_ROLES = 'super admin'

export interface UserContextInterface {
  user: UserInterface | null
  setUser(user: UserInterface): void
  removeUser(): void
}

const defaultUserContext: UserContextInterface = {
  user: null,
  setUser: noop,
  removeUser: noop,
}

export const UserContext = React.createContext(defaultUserContext)

enum actions {
  SET_USER = 'SET_USER',
  REMOVE_USER = 'REMOVE_USER',
}

type ActionType =
  | {
      type: actions.SET_USER
      user: UserInterface
    }
  | {
      type: actions.REMOVE_USER
    }

interface UserProviderProps {
  children: JSX.Element
}

function reducer(state: UserInterface | null, action: ActionType): UserInterface | null {
  switch (action.type) {
    case actions.SET_USER: {
      const user: UserInterface = { ...action.user }
      return user
    }
    case actions.REMOVE_USER:
      return null
    default:
      return state
  }
}

function UserProvider(props: UserProviderProps): JSX.Element {
  const [userState, dispatch] = useReducer(reducer, null)

  function setUser(user: UserInterface): void {
    dispatch({ type: actions.SET_USER, user })
  }

  function removeUser(): void {
    dispatch({ type: actions.REMOVE_USER })
  }

  return (
    <UserContext.Provider
      value={{
        user: userState,
        setUser,
        removeUser,
      }}
    >
      {props.children}
    </UserContext.Provider>
  )
}

export default UserProvider
