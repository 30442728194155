import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { Auth } from '@quiqupltd/quiqupjs'
import Routes from '../../types/routes.type'

interface Props {
  component: React.ReactType
  path?: string
  exact?: boolean
  isAllowed?: boolean
}

function PrivateRoute({ component: Component, isAllowed, ...rest }: Props): JSX.Element {
  return (
    <Route
      {...rest}
      render={(routeProps) =>
        Auth.hasToken() && isAllowed ? (
          <Component {...routeProps} />
        ) : (
          <Redirect
            to={{
              pathname: Routes.LOGIN,
              state: { from: routeProps.location },
            }}
          />
        )
      }
    />
  )
}

PrivateRoute.defaultProps = {
  requiresLoggedOut: false,
  isAllowed: true,
}

export default PrivateRoute
