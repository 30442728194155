import { useReducer, useEffect } from 'react'
import { ApiClient } from '@quiqupltd/quiqupjs'
import { AddressHistoryEventInterface } from '../types/history-address.type'
import { loadEnv } from '../configEnv'

interface StateInterface {
  result: AddressHistoryEventInterface[] | []
  loading: boolean
  error: null | string
}

enum actions {
  FETCH_INIT = 'FETCH_INIT',
  FETCH_SUCCESS = 'FETCH_SUCCESS',
  FETCH_FAILURE = 'FETCH_FAILURE',
}

const initialState: StateInterface = {
  result: [],
  error: null,
  loading: false,
}

async function fetchAddress(id: string): Promise<AddressHistoryEventInterface[]> {
  const env = await loadEnv()
  return ApiClient.get({ path: env.EX_CORE_API_URL + `/orders/${id}/address_changes` })
}

// Reducer
type Action =
  | { type: actions.FETCH_INIT }
  | { type: actions.FETCH_SUCCESS; response: AddressHistoryEventInterface[] }
  | { type: actions.FETCH_FAILURE; error: string }

function reducer(state: StateInterface, action: Action): StateInterface {
  switch (action.type) {
    case actions.FETCH_INIT:
      return { ...state, loading: true }
    case actions.FETCH_SUCCESS: {
      return { ...state, result: action.response, loading: false }
    }
    case actions.FETCH_FAILURE: {
      return { ...state, loading: false, error: action.error }
    }
    default:
      return state
  }
}

export function useAddressHistory(id: string): StateInterface {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    async function setAddress(): Promise<void> {
      try {
        dispatch({ type: actions.FETCH_INIT })
        const response = await fetchAddress(id)

        dispatch({ type: actions.FETCH_SUCCESS, response })
      } catch (error) {
        dispatch({ type: actions.FETCH_FAILURE, error })
      }
    }

    setAddress()
  }, [id])

  return state
}
