import React, { useEffect } from 'react'
import styled from 'styled-components'
import { space } from '@quiqupltd/ui-kit.sizes'
import { Modal as AntModal, Button, Form, Input, Alert } from 'antd'
import { Origin, Destination, UpdateContactPhoneFormValues } from '../../types/order.type'

const Modal = styled(AntModal)`
  width: ${space(80)}px !important;
`

interface Props {
  isOpen: boolean
  onClose: () => void
  onSubmit: (contactPhone: UpdateContactPhoneFormValues, waypointType: string) => void
  contact: Origin | Destination
  isLoading: boolean
  errorMessage?: string
  waypointType: string
}

function UpdatePhoneModal(props: Props): JSX.Element {
  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue(props.contact)
  }, [form, props.contact])

  function handleClose(): void {
    form.resetFields()
    props.onClose()
  }

  function handleSubmit(values: UpdateContactPhoneFormValues): void {
    values.contactPhone = values.contactPhone.replace(/\+/g, '')
    props.onSubmit(values, props.waypointType)
    form.resetFields()
  }

  return (
    <Modal
      title="Update Phone Number"
      visible={props.isOpen}
      footer={[
        <Button key="back" onClick={handleClose} disabled={props.isLoading}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={form.submit}
          loading={props.isLoading}
          data-testid="update-contactPhone-submit"
        >
          OK
        </Button>,
      ]}
      onCancel={handleClose}
    >
      <div data-testid="update-contactPhone-modal">
        <Form
          layout="horizontal"
          onFinish={handleSubmit}
          data-testid="submission_form"
          name="submission_form"
          form={form}
          initialValues={props.contact}
        >
          <Form.Item
            label="Phone Number"
            colon={false}
            name="contactPhone"
            rules={[
              {
                required: true,
                message: 'phone number is required',
              },
            ]}
          >
            <Input placeholder="Phone Number" data-testid={'phoneNumber-input'} />
          </Form.Item>
        </Form>
      </div>
      {props.errorMessage && <Alert message={props.errorMessage} type="error" showIcon />}
    </Modal>
  )
}

export default UpdatePhoneModal
