import { useReducer, useEffect } from 'react'
import { ApiClient } from '@quiqupltd/quiqupjs'
import { CommentHistoryEventInterface } from '../types/history-comment.type'
import { loadEnv } from '../configEnv'

interface StateInterface {
  result: CommentHistoryEventInterface[] | []
  loading: boolean
  error: null | string
}

enum actions {
  FETCH_INIT = 'FETCH_INIT',
  FETCH_SUCCESS = 'FETCH_SUCCESS',
  FETCH_FAILURE = 'FETCH_FAILURE',
}

const initialState: StateInterface = {
  result: [],
  error: null,
  loading: false,
}

async function fetchComments(id: string | undefined): Promise<CommentHistoryEventInterface[]> {
  const env = await loadEnv()
  const response = await ApiClient.get({
    path: env.EX_CORE_API_URL + `/api/comments?resource_type=client_order&resource_id=${id}`,
  })
  return response?.comments
}

// Reducer
type Action =
  | { type: actions.FETCH_INIT }
  | { type: actions.FETCH_SUCCESS; response: CommentHistoryEventInterface[] }
  | { type: actions.FETCH_FAILURE; error: string }

function reducer(state: StateInterface, action: Action): StateInterface {
  switch (action.type) {
    case actions.FETCH_INIT:
      return { ...state, loading: true }
    case actions.FETCH_SUCCESS: {
      return { ...state, result: action.response, loading: false }
    }
    case actions.FETCH_FAILURE: {
      return { ...state, loading: false, error: action.error }
    }
    default:
      return state
  }
}

export function useCommentHistory(id: string | undefined): StateInterface {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    async function setComment(): Promise<void> {
      try {
        dispatch({ type: actions.FETCH_INIT })
        const response = await fetchComments(id)

        dispatch({ type: actions.FETCH_SUCCESS, response })
      } catch (error) {
        dispatch({ type: actions.FETCH_FAILURE, error })
      }
    }

    setComment()
  }, [id])

  return state
}
