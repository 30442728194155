import React from 'react'
import { WarningOutlined } from '@ant-design/icons'
import { notification } from 'antd'
import FileSaver from 'file-saver'
import { format } from 'date-fns'
import { Gateway } from '@quiqupltd/quiqupjs'
import { OrderWithScanned } from '../types/order.type'

const REJECTED_DOWNLOAD_LABELS_STATES = ['cancelled']

const downloadLabels = async (recognizedOrders: OrderWithScanned[]): Promise<void> => {
  if (!recognizedOrders.length) {
    notification.open({
      message: `Unrecognized Orders`,
      description: 'You have no recognized orders to download labels for',
      icon: <WarningOutlined style={{ color: '#108ee9' }} />,
    })
  }
  for (const order of recognizedOrders) {
    if (REJECTED_DOWNLOAD_LABELS_STATES.includes(order.state)) {
      notification.open({
        message: `Wrong Order State`,
        description: 'One or more of the items have an invalid state',
        icon: <WarningOutlined style={{ color: '#108ee9' }} />,
      })
      return
    }
  }
  const orderIds = recognizedOrders.map((order) => order.id)
  try {
    const labels = await Gateway.ordersLabels({ id: orderIds })
    const buff = await labels.arrayBuffer()
    const blob = new Blob([new Uint8Array(buff)])
    const filename = `order${orderIds.length > 1 ? `s_${format(new Date(), 'd-M-yyyy')}` : `_${orderIds}`}.pdf`
    FileSaver.saveAs(blob, filename)
  } catch (err) {
    notification.open({
      message: `Could not download the label${orderIds.length > 1 ? 's' : ''}`,
      description: 'Please refresh and try again.',
      icon: <WarningOutlined style={{ color: '#108ee9' }} />,
    })
  }
}

export default downloadLabels
