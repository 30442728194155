import { format, parseISO } from 'date-fns'

export const DATE_FORMAT = 'd-M-yyyy'
export const TIME_FORMAT = 'HH:mm'
export const DATE_TIME_FORMAT = `${DATE_FORMAT} - ${TIME_FORMAT}`
export const MISSION_UPDATED_FORMAT = 'dd/yy [at] HH:mm'

export const getFormatedDateTime = (date: Date | string | undefined | null): string => {
  if (!date) {
    return ''
  }

  if (typeof date === 'string') {
    return format(parseISO(date), DATE_TIME_FORMAT)
  }

  return format(date, DATE_TIME_FORMAT)
}
