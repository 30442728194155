import { StaticContext } from 'react-router'
import React, { useEffect, useState, useMemo } from 'react'
import queryString, { StringifiableRecord } from 'query-string'
import { Table as AntTable, Result, Button, message, Badge, Tooltip, Pagination, DatePicker } from 'antd'
import { SorterResult, ColumnType } from 'antd/lib/table/interface'
import { RouteComponentProps } from 'react-router-dom'
import { parseISO } from 'date-fns'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import difference from 'lodash/difference'
import { Orders } from '@quiqupltd/quiqupjs'
import { hasLiveMission, getStateDot } from '../../utils/orders'
import {
  OrderInterface,
  OrderStatesLabels,
  OrderKindLabels,
  OrderKindShortCodes,
  KindMapped,
  StateMapped,
  OrderKinds,
  OrderStates,
  Destination,
  AddressChecked,
} from '../../types/order.type'
import Routes from '../../types/routes.type'
import FiltersModal from './FiltersModal'
import SearchFilter from './SearchFilter'
import { useOrders, OrdersParams, allowedSearchType, AllowedOrderByType, getOneMonthRange } from '../../hooks/orders'
import { useZones } from '../../hooks/zones'
import {
  ActionRow,
  ActionRowSection,
  AddressDescription,
  AddressName,
  BulkActionsButton,
  Container,
  Id,
  ExternalId,
  Kind,
  State,
  Total,
} from './StyledElements'
import FiltersButtons from './FiltersButtons'
import { getFormatedDateTime } from '../../utils/time'
import DatePickerFilterButtons from './DatePickerFilterButtons'
import moment, { Moment } from 'moment'

const { RangePicker } = DatePicker

// Interfaces
interface RowInterface {
  key: number
  client: string
  origin: string
  awb: string
  orderReference: number
  partnerOrderId: string
  mission: string
  state: string
  lastUpdate: string
  receiver: string
  destination: string
  parcels: string
  value: string
  attempts: string
  createdAt: string
  region: string
}

interface Props
  extends RouteComponentProps<
    { [key: string]: string | undefined }, // props.match.params.myParamProp
    StaticContext, // history
    {
      search?: string
      message?: string
      selectedOrders?: OrderInterface[]
    } // props.location.state.myStateProp
  > {
  children: React.ReactNode
}

interface SelectedOrders {
  [key: string]: OrderInterface
}

interface OrdersCountResponse {
  serviceKinds: {
    [key in keyof typeof KindMapped]: number
  }
  states: {
    [key in keyof typeof StateMapped]: number
  }
}

const columnFields = {
  client: 'client',
  origin: 'origin',
  zone: 'zone',
  destinationRegion: 'destinationRegion',
  awb: 'awb',
  orderReference: 'orderReference',
  partnerOrderId: 'partnerOrderId',
  state: 'state',
  kind: 'kind',
  receiver: 'receiver',
  lastUpdate: 'lastUpdate',
  destination: 'destination',
  parcels: 'parcels',
  value: 'value',
  attempts: 'attempts',
  createdAt: 'createdAt',
  region: 'region',
  deliveryBefore: 'deliveryBefore',
}

function isPartnerReturn(orders: OrderInterface[], orderId: number): boolean | null {
  const order = orders.find((o) => o.id === orderId)
  if (order && order.serviceKind) return order.serviceKind === 'partner_return'

  return null
}

function getMissionRootUrl(regionName: string, missionId: string) {
  if (missionId.startsWith('MI_')) {
    return regionName === 'Dubai' ? window.env.betadubaiDispatchUrl : window.env.betaabudhabiDispatchUrl
  }
  return regionName === 'Dubai' ? window.env.dubaiDispatchUrl : window.env.abudhabiDispatchUrl
}

function getMissionState(orders: OrderInterface[], orderId: number): string {
  const order = orders.find((o) => o.id === orderId)
  if (order && order.mission) return order.mission.state

  return ''
}

function getOrderKind(orders: OrderInterface[], orderId: number): Order.ServiceKind | null {
  const order = orders.find((o) => o.id === orderId)
  if (order && order.serviceKind) return order.serviceKind

  return null
}
function getOrderFullDestination(orders: OrderInterface[], orderId: number): Destination | null {
  const order = orders.find((o) => o.id === orderId)
  if (order && order.destination) return order.destination

  return null
}

function getOriginDetails(
  orders: OrderInterface[],
  orderId: number
): { origin: string; client?: string; addressChecked: boolean | null } | null {
  const order = orders.find((o) => o.id === orderId)
  if (order && order.origin) {
    return {
      origin: order.origin.address?.address1,
      client: order.user?.fullname,
      addressChecked: order.origin?.checked,
    }
  }

  return null
}

export function getCreatedAt(orderState: Order.State, orderCreatedAt: string, orderSubmittedAt: string): string {
  const date = orderState === 'pending' ? orderCreatedAt : orderSubmittedAt
  return date
    ? formatDistanceToNow(parseISO(date), {
        addSuffix: true,
      })
    : ''
}

export const getDeliveryBefore = (deliveryBefore: OrderInterface['deliveryTime']['deliveryBefore']): string => {
  return `${getFormatedDateTime(deliveryBefore)}`
}

function formatOrdersIntoRows(orders: OrderInterface[]): RowInterface[] {
  return orders.map((order) => {
    return {
      key: order.id,
      client: order.user?.fullname,
      origin: order.origin.address?.address1,
      zone: order.destination.zone?.sector ?? 'N/A',
      destinationRegion: order.destination.emirate,
      awb: order.items && order.items.length > 0 ? order.items[0].parcelBarcode || order.items[0].name : '',
      orderReference: order.id,
      partnerOrderId: `${order.partnerOrderId || ''}`,
      mission: `${order.mission?.id || ''}`,
      state: OrderStatesLabels[order.state],
      receiver: order.destination.contactName,
      lastUpdate: order.stateUpdatedAt
        ? formatDistanceToNow(parseISO(order.stateUpdatedAt), {
            addSuffix: true,
          })
        : '',
      destination: order.destination.address?.address1,
      parcels: String(order.items.length),
      value: order.paymentAmount,
      attempts: String(order.deliveryAttempts),
      createdAt: getCreatedAt(order.state, order.createdAt, order.submittedAt),
      region: order.regionName,
      deliveryBefore: getDeliveryBefore(order.deliveryTime.deliveryBefore),
    }
  })
}

const updateSelectedJobs = (
  ordersHash: SelectedOrders,
  selectedOrders: string[],
  orders: OrderInterface[]
): SelectedOrders => {
  const hash = { ...ordersHash } // copy the hash as we're about to mutate it
  const orderKeys = Object.keys(hash)
  const removedOrderIds = difference(orderKeys, selectedOrders)
  const newOrderIds = difference(selectedOrders, orderKeys)

  removedOrderIds.forEach((id: string) => delete hash[id])
  newOrderIds.forEach((id: string) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    hash[id] = orders.find((order) => String(order.id) === id)!
  })

  return hash
}

function getOrderStates(orderStates?: OrderStates[] | null): OrderStates[] {
  if (!orderStates) {
    return []
  }

  if (typeof orderStates === 'string') {
    return [orderStates]
  }

  return orderStates
}

function getOrderKinds(orderKinds?: OrderKinds[] | null): OrderKinds[] {
  if (!orderKinds) {
    return []
  }

  if (typeof orderKinds === 'string') {
    return [orderKinds]
  }

  return orderKinds
}

function getDatePickerRange(dateRange?: string[] | null): string[] {
  if (!dateRange) {
    return []
  }
  if (typeof dateRange === 'string') {
    return [dateRange]
  }

  return dateRange
}

function getDatePickerDefaultRange(dateRange?: string[] | null): [Moment, Moment] | null | undefined {
  if (!dateRange) {
    return null
  }
  if (typeof dateRange === 'string') {
    return [moment(dateRange, 'DD-MM-YYYY'), moment(dateRange, 'DD-MM-YYYY')]
  }

  return [moment(dateRange[0], 'DD-MM-YYYY'), moment(dateRange[1], 'DD-MM-YYYY')]
}

function getDeliveryBeforeDates(deliveryBeforeDates?: string[] | null): string[] {
  if (!deliveryBeforeDates) {
    return []
  }

  if (typeof deliveryBeforeDates === 'string') {
    return [deliveryBeforeDates]
  }

  return deliveryBeforeDates
}
function getInitialZones(zones?: string[] | null): string[] {
  if (!zones) {
    return []
  }

  if (typeof zones === 'string') {
    return [zones]
  }

  return zones
}

function getOrdersParams(
  page: number,
  orderStates: OrderStates[],
  orderKinds: OrderKinds[],
  quickDeliveryBeforeDates?: string[],
  isAddressChecked?: AddressChecked,
  searchType?: allowedSearchType,
  searchText?: string,
  sortBy?: AllowedOrderByType,
  region?: Region.Code,
  zones?: string[],
  dateType?: string,
  dateRange?: string[],
  destinationRegion?: Region.Code
): OrdersParams {
  const params: OrdersParams = { page, states: orderStates, kinds: orderKinds }
  if (searchType) params.searchType = searchType
  if (searchText) params.searchText = searchText
  if (sortBy) params.sortBy = sortBy
  if (region) params.region = region
  if (zones?.length) params.zones = zones
  if (dateType) params.dateType = dateType
  if (dateRange?.length) params.dateRange = dateRange
  if (destinationRegion) params.destinationRegion = destinationRegion
  params.isAddressChecked = isAddressChecked
  if (quickDeliveryBeforeDates?.length) params.deliveryBeforeDates = quickDeliveryBeforeDates

  return params
}

function createSortKey(field: string, order: 'ascend' | 'descend'): AllowedOrderByType {
  const newOrder = order === 'ascend' ? 'asc' : 'desc'
  if (field === columnFields.lastUpdate) {
    return `state_updated_at_${newOrder}` as AllowedOrderByType
  }

  if (field === columnFields.deliveryBefore) {
    return `delivery_before_${newOrder}` as AllowedOrderByType
  }

  if (field === columnFields.createdAt) {
    return `created_at_${newOrder}` as AllowedOrderByType
  }

  return `delivery_attempts_${newOrder}` as AllowedOrderByType
}

const { oneMonthFrom, oneMonthTo } = getOneMonthRange()

type RegionKeys = Region.Slug | 'all'
type RegionCodes = Region.Code | undefined
type RegionLabels = Region.Label | 'All Regions'

const REGION_KEYS: RegionKeys[] = ['all', 'dubai', 'abuDhabi']

const regionLabels: { [key in RegionKeys]: RegionLabels } = {
  all: 'All Regions',
  dubai: 'Dubai',
  abuDhabi: 'Abu Dhabi',
}

const regionCodes: { [key in RegionKeys]: RegionCodes } = {
  all: undefined,
  dubai: 'uae.dubai',
  abuDhabi: 'uae.abu_dhabi',
}

interface RegionMap<Type> {
  [id: string]: Type
}

const codeToRegion: RegionMap<string> = {
  all: 'all',
  uaedubai: 'dubai',
  uaeabu_dhabi: 'abuDhabi',
}

// Component
function OrdersTable(props: Props): JSX.Element | null {
  const routeParams = queryString.parse(props.location.search, {
    arrayFormat: 'comma',
  })
  const [page, setPage] = useState<number>(Number(routeParams.page) || 1)
  const [selectedOrdersHash, setSelectedOrdersHash] = useState<SelectedOrders>({})
  const [statesCount, setStatesCount] = useState<Record<string, number>>({})
  const [kindsCount, setKindsCount] = useState<Record<string, number>>({})
  const [sortBy, setSortBy] = useState<AllowedOrderByType | null>(null)
  const [sorterInfo, setSorterInfo] = useState<SorterResult<RowInterface>>()
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false)
  const [searchType, setSearchType] = useState<allowedSearchType | ''>('')
  const [searchText, setSearchText] = useState('')
  const initialOrderState = routeParams.states as OrderStates[]
  const [orderStates, setOrderStates] = useState<OrderStates[]>(getOrderStates(initialOrderState))
  const initialOrderKinds = routeParams.kinds as OrderKinds[]
  const [orderKinds, setOrderKinds] = useState<OrderKinds[]>(getOrderKinds(initialOrderKinds))
  const initialRegion = routeParams.region as RegionCodes
  const [selectedRegion, setSelectedRegion] = useState<RegionCodes>(initialRegion)
  const initialDestinationRegion = routeParams.destinationRegion as RegionCodes
  const [selectedDestinationRegion, setSelectedDestinationRegion] = useState<RegionCodes>(initialDestinationRegion)
  const initialZones = routeParams.zones as string[]
  const [selectedZones, setSelectedZones] = useState<string[]>(getInitialZones(initialZones))

  const [isAddressChecked, setIsAddressChecked] = useState<AddressChecked>()
  const initialDatePickerOption = routeParams.dateType as string
  const initialDatePickerRange = routeParams.dateRange as string[]
  const [selectedDatePickerOption, setSelectedDatePickerOption] = useState<string | undefined>(initialDatePickerOption)
  const [selectedDates, setSelectedDates] = useState<string[]>(getDatePickerRange(initialDatePickerRange))
  const initialDeliveryBeforeDates = routeParams.deliveryBeforeDates as string[]
  const [quickDeliveryBeforeDates, setQuickDeliveryBeforeDates] = useState<string[]>(
    getDeliveryBeforeDates(initialDeliveryBeforeDates)
  )

  const ordersParams = getOrdersParams(
    page,
    orderStates,
    orderKinds,
    quickDeliveryBeforeDates,
    isAddressChecked,
    searchType || undefined,
    searchText,
    sortBy || undefined,
    selectedRegion || undefined,
    selectedZones,
    selectedDatePickerOption || undefined,
    selectedDates,
    selectedDestinationRegion || undefined
  )

  const getColumns = (
    orders: OrderInterface[],
    sortedInfo?: SorterResult<RowInterface>
  ): ColumnType<RowInterface>[] => {
    let orderId: string | number
    const columns: ColumnType<RowInterface>[] = [
      {
        title: 'Origin',
        dataIndex: columnFields.orderReference,
        key: columnFields.origin,
        width: '190px',
        // eslint-disable-next-line react/display-name
        render: (reference) => {
          orderId = reference
          if (!orderId) orderId = orders[0].id //To handle first iteration before orderId is set in the orderReference column
          const details = getOriginDetails(orders, Number(orderId))
          const is_partner_return = isPartnerReturn(orders, Number(orderId))

          return (
            <>
              <AddressName className="client">
                {is_partner_return && details?.addressChecked ? (
                  <Tooltip title="This address was verified">{`✅ `}</Tooltip>
                ) : null}
                {details?.client}
              </AddressName>
              <AddressDescription className="origin">{details?.origin}</AddressDescription>
            </>
          )
        },
      },
      {
        title: 'Zone',
        dataIndex: columnFields.zone,
        key: columnFields.zone,
        className: columnFields.zone,
        filters: zones.results.map((zone) => {
          return { text: zone, value: zone }
        }),
        filterSearch: true,
        width: 80,
      },
      {
        title: 'Destination Region',
        dataIndex: columnFields.destinationRegion,
        className: columnFields.destinationRegion,
        key: columnFields.destinationRegion,
        filters: REGION_KEYS.map((regionKey) => {
          return { text: regionLabels[regionKey], value: regionKey }
        }),
        defaultFilteredValue: [
          initialDestinationRegion ? codeToRegion[initialDestinationRegion.replace('.', '')] : 'all',
        ],
        filterSearch: true,
        filterMultiple: false,
        width: 110,
      },
      {
        title: 'AWB',
        dataIndex: columnFields.awb,
        className: columnFields.awb,
        key: columnFields.awb,
      },
      {
        title: 'Order reference',
        dataIndex: columnFields.orderReference,
        className: columnFields.orderReference,
        key: columnFields.orderReference,
        // eslint-disable-next-line react/display-name
        render: (reference) => {
          return <Id to={`/order/${reference}`}>{reference}</Id>
        },
      },
      {
        title: 'Partner Order ID',
        dataIndex: columnFields.partnerOrderId,
        className: columnFields.partnerOrderId,
        key: columnFields.partnerOrderId,
        width: 100,
      },
      {
        title: 'State',
        dataIndex: columnFields.state,
        className: columnFields.state,
        key: columnFields.state,
        width: 100,
        // eslint-disable-next-line react/display-name
        render: (state, row) => {
          const missionState = getMissionState(orders, Number(orderId))
          const isLive = hasLiveMission(missionState)
          const dot = getStateDot(isLive, state)
          const rootPath = getMissionRootUrl(row.region, row.mission)

          return isLive ? (
            <ExternalId href={`${rootPath}${row.mission}`} target="_blank">
              <State>
                <span className="state-text">{state}</span>
                <Tooltip placement="top" title={dot.description}>
                  <Badge color={dot.color} />
                </Tooltip>
              </State>
            </ExternalId>
          ) : (
            <State>
              <span className="state-text">{state}</span>
              <Tooltip placement="top" title={dot.description}>
                <Badge color={dot.color} />
              </Tooltip>
            </State>
          )
        },
      },
      {
        title: 'Kind',
        dataIndex: columnFields.kind,
        className: columnFields.kind,
        key: columnFields.kind,
        width: '60px',
        // eslint-disable-next-line react/display-name
        render: () => {
          const serviceKind = getOrderKind(orders, Number(orderId))
          if (!serviceKind) {
            return null
          }

          return (
            <Tooltip placement="top" title={OrderKindLabels[serviceKind]}>
              <Kind>{OrderKindShortCodes[serviceKind]}</Kind>
            </Tooltip>
          )
        },
      },
      {
        title: 'Last Update',
        dataIndex: columnFields.lastUpdate,
        className: columnFields.lastUpdate,
        key: columnFields.lastUpdate,
        sorter: true,
        sortOrder: sortedInfo?.columnKey === 'lastUpdate' ? sortedInfo.order : undefined,
        width: '120px',
      },
      {
        title: 'Destination',
        dataIndex: columnFields.destination,
        key: columnFields.destination,
        width: 130,
        // eslint-disable-next-line react/display-name
        render: () => {
          const destination = getOrderFullDestination(orders, Number(orderId))
          const is_partner_return = isPartnerReturn(orders, Number(orderId))

          return (
            <>
              <AddressName className="receiver">
                {!is_partner_return && destination?.checked ? '✅ ' : null}
                {destination?.contactName}
              </AddressName>
              <AddressDescription className="destination">{destination?.address?.address1}</AddressDescription>
            </>
          )
        },
      },
      {
        title: 'Current Region',
        dataIndex: columnFields.region,
        className: columnFields.region,
        key: columnFields.region,
        filters: REGION_KEYS.map((regionKey) => {
          return { text: regionLabels[regionKey], value: regionKey }
        }),
        defaultFilteredValue: [initialRegion ? codeToRegion[initialRegion.replace('.', '')] : 'all'],
        filterSearch: true,
        filterMultiple: false,
        width: 90,
      },
      {
        title: 'Parcels',
        dataIndex: columnFields.parcels,
        className: columnFields.parcels,
        key: columnFields.parcels,
      },
      {
        title: 'Delivery before',
        dataIndex: columnFields.deliveryBefore,
        className: columnFields.deliveryBefore,
        key: columnFields.deliveryBefore,
        sorter: true,
        sortOrder: sortedInfo?.columnKey === 'deliveryBefore' ? sortedInfo?.order : undefined,
        width: '150px',
      },
      {
        title: 'Attempts ',
        dataIndex: columnFields.attempts,
        className: columnFields.attempts,
        key: columnFields.attempts,
        sorter: true,
        sortOrder: sortedInfo?.columnKey === 'attempts' ? sortedInfo?.order : undefined,
        width: '100px',
      },
      {
        title: 'Created',
        dataIndex: columnFields.createdAt,
        className: columnFields.createdAt,
        key: columnFields.createdAt,
        sorter: true,
        sortOrder: sortedInfo?.columnKey === 'createdAt' ? sortedInfo?.order : undefined,
        width: '120px',
      },
    ]

    return columns
  }

  const orders = useOrders(ordersParams)

  const zones = useZones()

  const columns = getColumns(orders.results, sorterInfo)
  const rows = useMemo(() => formatOrdersIntoRows(orders.results), [orders.results])

  useEffect(() => {
    async function getFilterCounts(): Promise<void> {
      const { states, serviceKinds }: OrdersCountResponse = await Orders.getGroupCounts({
        from: oneMonthFrom,
        to: oneMonthTo,
        ...(selectedRegion && { 'filters[region]': selectedRegion }),
        ...(selectedDestinationRegion && { 'filters[destination_region]': selectedDestinationRegion }),
      })
      let snakedCount: {
        [key: string]: number
      } = {}
      ;(Object.keys(states) as (keyof typeof StateMapped)[]).forEach((state) => {
        const stateKey = StateMapped[state]
        const stateValue = states[state]
        snakedCount[stateKey] = stateValue
      })

      setStatesCount(snakedCount)
      snakedCount = {}
      ;(Object.keys(serviceKinds) as (keyof typeof KindMapped)[]).forEach((kind) => {
        const kindKey = KindMapped[kind]
        const kindValue = serviceKinds[kind]
        snakedCount[kindKey] = kindValue
      })

      setKindsCount(snakedCount)
    }

    getFilterCounts()
  }, [selectedRegion, selectedDestinationRegion])

  const locationMessage = props.location.state && props.location.state.message
  useEffect(() => {
    if (locationMessage) {
      message.success(locationMessage)
    }
  }, [locationMessage])

  useEffect(() => {
    if (!props.location.search) {
      setPage(1)
      setSortBy(null)
      setSearchText('')
      setSearchType('')
      setOrderStates([])
      setOrderKinds([])
      setSelectedOrdersHash({})
      setSorterInfo(undefined)
      setSelectedRegion(undefined)
      setSelectedDatePickerOption(undefined)
      setSelectedDates([])
      setSelectedDestinationRegion(undefined)
      setSelectedZones([])
      props.history.push('/')
    }
  }, [props.location.search, props.history])

  useEffect(() => {
    const newParams: StringifiableRecord = {
      page: String(page),
      states: orderStates,
      deliveryBeforeDates: quickDeliveryBeforeDates,
      isAddressChecked: isAddressChecked,
      kinds: orderKinds,
      dateType: selectedDatePickerOption,
      dateRange: selectedDates,
      zones: selectedZones,
      ...(selectedRegion && { region: selectedRegion }),
      ...(selectedDestinationRegion && { destinationRegion: selectedDestinationRegion }),
      ...(searchType && { searchType }),
      ...(searchText && { searchText }),
      ...(sortBy && { sortBy }),
    }

    const newRoute = queryString.stringify(newParams, {
      arrayFormat: 'comma',
      sort: false,
      encode: false,
    })

    if (newRoute !== props.location.search.slice(1)) {
      props.history.push(`/?${newRoute}`)
    }
  }, [
    orderStates,
    orderKinds,
    selectedDatePickerOption,
    selectedDates,
    quickDeliveryBeforeDates,
    selectedZones,
    isAddressChecked,
    props.history,
    props.location,
    page,
    searchType,
    searchText,
    sortBy,
    selectedRegion,
    selectedDestinationRegion,
  ])

  function handleSelectionChange(selectedRowKeys: (string | number)[] | number[]): void {
    const selectedRowKeyStrings: string[] = []

    selectedRowKeys.forEach((item: string | number) => {
      selectedRowKeyStrings.push(String(item))
    })

    const selectedOrders = updateSelectedJobs(selectedOrdersHash, selectedRowKeyStrings, orders.results)
    setSelectedOrdersHash(selectedOrders)
  }

  function handleBulkClick(): void {
    props.history.push({
      pathname: Routes.BULK_ACTIONS,
      state: {
        selectedOrders: Object.values(selectedOrdersHash),
      },
    })
  }

  function handleFiltersButtonClick(): void {
    setIsFiltersModalOpen(true)
  }

  function handleFiltersModalClose(): void {
    setIsFiltersModalOpen(false)
  }

  function handleFiltersSubmit(newStates: Order.State[], newKinds: Order.ServiceKind[]): void {
    setPage(1)
    setOrderStates(newStates)
    setOrderKinds(newKinds)
    setIsFiltersModalOpen(false)
  }

  function handleSearch(type: allowedSearchType | '', text: string): void {
    setSearchType(type)
    setSearchText(text)
  }

  function resetStateFilters(): void {
    setOrderStates([])
    setOrderKinds([])
    setQuickDeliveryBeforeDates([])
    setSelectedDatePickerOption(undefined)
    setSelectedDates([])
    setIsAddressChecked(undefined)
  }

  function handleDatePickerOptionClick(selectedOption: string, action: 'select' | 'deselect') {
    setPage(1)
    if (action === 'deselect') {
      setSelectedDatePickerOption(undefined)
    } else {
      setSelectedDatePickerOption(selectedOption)
    }
  }

  function handleDatePickerChange(dateOptions: unknown, dateOptionsString: [string, string]) {
    setQuickDeliveryBeforeDates([])

    if (JSON.stringify(dateOptionsString) === JSON.stringify(['', ''])) {
      setSelectedDatePickerOption(undefined)
    }

    setSelectedDates(dateOptionsString)
  }

  function handleQuickStateFilterClick(newStates: Order.State[], action: 'select' | 'deselect') {
    setPage(1)
    if (action === 'select') {
      setOrderStates([...orderStates, ...newStates])
    }

    if (action === 'deselect') {
      setOrderStates(
        newStates.reduce((states, newState) => {
          const indexToRemove = states.indexOf(newState)

          return states.filter((_, index) => index !== indexToRemove)
        }, orderStates)
      )
    }
  }

  function handleQuickAddressCheckedFilterClick(value: boolean) {
    setPage(1)
    setIsAddressChecked(value)
  }

  function handleQuickDeliveryBeforeFilterClick(value: string, action: 'select' | 'deselect') {
    setPage(1)
    setSelectedDatePickerOption(undefined)
    setSelectedDates([])

    if (action === 'select') {
      setQuickDeliveryBeforeDates([...quickDeliveryBeforeDates, value])
    }
    if (action === 'deselect') {
      setQuickDeliveryBeforeDates(quickDeliveryBeforeDates.filter((date) => date !== value))
    }
  }

  function handleSelectedRegionChange(regionKey: unknown): void {
    setSelectedRegion(regionCodes[regionKey as RegionKeys])
  }

  function handleSelectedDestinationRegionChange(regionKey: unknown): void {
    setSelectedDestinationRegion(regionCodes[regionKey as RegionKeys])
  }

  const handleSelectionZoneChange = (zones: string[]) => {
    setSelectedZones(zones)
  }

  function handleTableChange(
    pagination: unknown,
    filters: unknown,
    sorter: SorterResult<RowInterface> | SorterResult<RowInterface>[]
  ): void {
    const sorterValue = Array.isArray(sorter) ? sorter[0] : sorter
    setSorterInfo(sorterValue)
    if (sorterValue.field) {
      setSortBy(createSortKey(sorterValue.field as string, sorterValue.order ?? 'ascend'))
    } else {
      setSortBy(null)
    }

    const allFilters = filters as { zone: string[]; destinationRegion: unknown; region: unknown }

    if (allFilters.zone?.length) {
      handleSelectionZoneChange(allFilters.zone)
    } else {
      handleSelectionZoneChange([])
    }
    if (allFilters.region) {
      handleSelectedRegionChange(allFilters.region)
    } else {
      handleSelectedRegionChange(null)
    }
    if (allFilters.destinationRegion) {
      handleSelectedDestinationRegionChange(allFilters.destinationRegion)
    } else {
      handleSelectedRegionChange(null)
    }
  }

  function checkboxConfig(record: RowInterface): { disabled: boolean } {
    return {
      disabled: record.state !== OrderStatesLabels.ready_for_collection && record.state !== OrderStatesLabels.pending,
    }
  }

  if (orders.error) {
    return (
      <Result
        status="500"
        title="500"
        subTitle="Sorry, there was an error."
        extra={
          <Button type="primary" href={`/${props.location.search}`}>
            Try again
          </Button>
        }
      />
    )
  }

  return (
    <Container>
      <FiltersModal
        isOpen={isFiltersModalOpen}
        onClose={handleFiltersModalClose}
        onSubmit={handleFiltersSubmit}
        orderStates={orderStates}
        orderKinds={orderKinds}
        statesCount={statesCount}
        kindsCount={kindsCount}
        resetStateFilters={resetStateFilters}
      />
      <ActionRow>
        <ActionRowSection>
          <SearchFilter onSearch={handleSearch} isLoading={orders.loading} shouldReset={!props.location.search} />
          <RangePicker
            allowClear={true}
            allowEmpty={[false, true]}
            onChange={handleDatePickerChange}
            defaultValue={getDatePickerDefaultRange(initialDatePickerRange)}
            format={'DD-MM-YYYY'}
            renderExtraFooter={() => (
              <div>
                <div>Filter by:</div>
                <DatePickerFilterButtons
                  selectedDateOption={selectedDatePickerOption}
                  onClickDatePickerOption={handleDatePickerOptionClick}
                ></DatePickerFilterButtons>
              </div>
            )}
          />
        </ActionRowSection>
        <ActionRowSection end="true">
          {!orders.loading && <Total>Total: {orders.total}</Total>}
          <BulkActionsButton
            type="primary"
            disabled={orders.loading}
            onClick={handleBulkClick}
            data-testid="bulk-actions-button"
          >
            Bulk actions
          </BulkActionsButton>
        </ActionRowSection>
      </ActionRow>
      <ActionRow>
        <ActionRowSection>
          <FiltersButtons
            activeStateFilters={orderStates}
            activeKindFilters={orderKinds}
            disabled={orders.loading}
            isAddressChecked={isAddressChecked}
            openFiltersModal={handleFiltersButtonClick}
            onClickQuickStateFilter={handleQuickStateFilterClick}
            onClickQuickAddressCheckedFilter={handleQuickAddressCheckedFilterClick}
            onClearFilters={resetStateFilters}
            statesCount={statesCount}
            onClickQuickDeliveryBeforeFilter={handleQuickDeliveryBeforeFilterClick}
            activeDeliveryBeforeDates={quickDeliveryBeforeDates}
          />
        </ActionRowSection>
        <ActionRowSection end="true">
          <Pagination
            simple
            defaultCurrent={page}
            total={orders.total || 1}
            onChange={setPage}
            current={page}
            pageSize={orders.perPage}
            data-testid="page-counter"
          />
        </ActionRowSection>
      </ActionRow>
      <AntTable
        size="middle"
        columns={columns}
        dataSource={rows || undefined}
        pagination={false}
        scroll={{ y: window.innerHeight - 160, x: 1600 }}
        loading={orders.loading}
        rowSelection={{
          onChange: handleSelectionChange,
          getCheckboxProps: checkboxConfig,
          fixed: true,
          columnWidth: 42,
        }}
        onChange={handleTableChange}
      />
    </Container>
  )
}

export default OrdersTable
