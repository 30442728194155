import React, { useState, useContext } from 'react'
import { space } from '@quiqupltd/ui-kit.sizes'
import { Form, Button, Card, Input, Alert } from 'antd'
import { Redirect } from 'react-router-dom'
import { Auth, Partner } from '@quiqupltd/quiqupjs'
import styled from 'styled-components'
import UserInterface from '../../types/user.type'
import { ReactComponent as Logo } from '../../assets/banner-logo.svg'
import { ReactComponent as Corner } from '../../assets/dots-corner.svg'
import { UserContext } from '../../context/user.context'

const Container = styled.div`
  height: 100vh;
  background: ${(p) => p.theme.midnight500};
  display: flex;
  justify-content: center;
  align-items: center;
`

const MainCard = styled(Card)`
  width: ${space(50)}px;
`

const QLogo = styled(Logo)`
  margin: 0 auto 1rem;
  width: 100%;
`

const DotsCorner = styled(Corner)`
  position: absolute;
  right: 0;
  bottom: 0px;
  width: 50px;
  height: 50px;
`

interface User {
  auth?: string
  expiry?: number
  refresh?: string
}

enum AuthErrors {
  auth = 'Your username or password is not recognized',
  roles = 'You must be an Admin and CSR to access',
}

function LoginForm(): JSX.Element {
  const [form] = Form.useForm()
  const context = useContext(UserContext)
  const [loading, setLoading] = useState(false)
  const [authError, setAuthError] = useState('')
  const [authUser, setAuthUser] = useState<User>({})

  function handleSubmit(): void {
    form.validateFields().then((values) => {
      const tryToLogin = async () => {
        setLoading(true)

        try {
          const loggedUser = await Auth.login(values.email, values.password)
          const user: UserInterface = await Partner.user.get()

          if (!(user.admin && user.csr)) {
            await Auth.removeToken()
            throw new Error(AuthErrors.roles)
          }

          context.setUser(user)
          setLoading(false)
          setAuthUser(loggedUser)
        } catch (error) {
          if (error instanceof Error && error.message === AuthErrors.roles) {
            setAuthError(AuthErrors.roles)
          } else {
            setAuthError(AuthErrors.auth)
          }

          setLoading(false)
        }

        setLoading(false)
      }

      tryToLogin()
    })
  }

  if (authUser.auth) return <Redirect to="/" />

  return (
    <Container>
      <div>
        <QLogo />
        <MainCard>
          {authError.length > 0 && <Alert message={authError} type="error" showIcon />}
          <h1>
            Welcome{' '}
            <span role="img" aria-label="wave">
              👋
            </span>
          </h1>
          <p>Log in to manage Quiqup warehouse orders</p>
          <Form onFinish={handleSubmit} data-testid="login-form" name="login_form" form={form}>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: 'Email is required' },
                { type: 'email', message: 'Must be a valid email' },
              ]}
            >
              <Input type="email" placeholder="Email" size="large" />
            </Form.Item>
            <Form.Item name="password" rules={[{ required: true, message: 'Password is required' }]}>
              <Input.Password placeholder="Password" size="large" />
            </Form.Item>
            <Button type="primary" htmlType="submit" size="large" loading={loading} data-testid="login-submit">
              Log in
            </Button>
          </Form>
          <DotsCorner />
        </MainCard>
      </div>
    </Container>
  )
}

export default LoginForm
