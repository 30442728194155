import React from 'react'
import { Form, Switch } from 'antd'
import styled from 'styled-components'

interface Props {
  label: string
  identifier: string
}

const StyledFormItem = styled(Form.Item)`
  margin-left: 20px;
`

function SwitchField(props: Props): JSX.Element {
  return (
    <StyledFormItem label={props.label} colon={false} name={props.identifier} valuePropName="checked">
      <Switch />
    </StyledFormItem>
  )
}

export default SwitchField
