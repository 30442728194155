import React, { useState } from 'react'
import { space } from '@quiqupltd/ui-kit.sizes'
import { Button } from 'antd'
import { CheckOutlined, CopyOutlined, EyeOutlined } from '@ant-design/icons'
import styled from 'styled-components'

const Container = styled.div`
  padding-top: 5px;

  > :nth-last-child(n + 2) {
    margin-right: ${space(1)}px !important;
  }
`

const TrackingUrl = ({ url }: { url: string }): JSX.Element => {
  const [hasBeenCopied, setHasBeenCopied] = useState(false)
  const handleClick = () => {
    navigator.clipboard.writeText(url)
    setHasBeenCopied(true)
  }

  return (
    <Container>
      <Button onClick={handleClick} data-testid="copy-button">
        {hasBeenCopied ? (
          <span>
            <CheckOutlined /> copied
          </span>
        ) : (
          <span>
            <CopyOutlined /> copy
          </span>
        )}
      </Button>
      <Button href={url} target="_blank" data-testid="open-button">
        <EyeOutlined /> open
      </Button>
    </Container>
  )
}

export default TrackingUrl
