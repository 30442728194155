import React from 'react'
import styled from 'styled-components'
import { Button } from 'antd'

interface Props {
  selectedDateOption: string | undefined
  onClickDatePickerOption: (value: string, action: 'select' | 'deselect') => void
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0 0 0;
  flex-flow: wrap;

  > :nth-last-child(n + 2) {
    margin-right: 8px !important;
  }

  &:nth-of-type(n + 2) {
    padding: 8px 0;
  }
`
type DatePickerOptions = Array<{ label: string; value: string }>

export const DATE_PICKER_OPTIONS: DatePickerOptions = [
  {
    label: 'Date created',
    value: 'date_created',
  },
  {
    label: 'Delivery before',
    value: 'delivery_before',
  },
  {
    label: 'Last updated',
    value: 'last_updated',
  },
]

const DatePickerFilterButtons = ({ selectedDateOption, onClickDatePickerOption }: Props): JSX.Element => {
  return (
    <Container>
      {DATE_PICKER_OPTIONS.map((option) => {
        const active = selectedDateOption === option.value
        return (
          <Button
            type={active ? 'primary' : 'default'}
            key={option.value}
            onClick={() => onClickDatePickerOption(option.value, active ? 'deselect' : 'select')}
          >
            {`${option.label}`}
          </Button>
        )
      })}
    </Container>
  )
}

export default DatePickerFilterButtons
