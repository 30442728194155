import React from 'react'
import styled from 'styled-components'
import { format, parseISO } from 'date-fns'
import { Table as AntTable, Timeline } from 'antd'
import { AddressHistoryEventInterface } from '../../types/history-address.type'
import { DATE_TIME_FORMAT } from '../../utils/time'

type Props = {
  history: AddressHistoryEventInterface[]
}

const Table = styled(AntTable)`
  td {
    border-bottom: none !important;
  }

  thead th {
    color: ${(p) => p.theme.midnight500} !important;
    background-color: transparent !important;
    border: none !important;
  }

  thead th:before {
    display: none;
  }

  tbody > tr {
    background-color: transparent;
  }

  .ant-table-tbody:last-of-type > .ant-timeline-item > .ant-timeline-item-tail {
    display: none;
  }
`
const TimelineDot = styled(Timeline.Item)`
  position: absolute !important;
  height: 100% !important;
  transform: translateY(calc(50% - 5px)) !important;
  padding-bottom: 0 !important;
  z-index: 999;
`

const TableBody = styled.tbody`
  position: relative !important;
`

function AddressHistory(props: Props): JSX.Element {
  const dataSource = props.history.map((event, index) => {
    return {
      date: event.occurredAt ? format(parseISO(event.occurredAt), DATE_TIME_FORMAT) : '',
      address1: event.address1,
      address2: event.address2,
      apartmentNumber: event.apartmentNumber,
      contactPhone: event.contactPhone,
      coords: event.coords?.toString(),
      town: event.town,
      user: event?.author ?? '',
      country: event.country,
    }
  })

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      width: '180px',
    },
    {
      title: 'Address 1',
      dataIndex: 'address1',
      key: 'address1',
      width: '180px',
    },
    {
      title: 'Address 2',
      dataIndex: 'address2',
      key: 'address2',
      width: '180px',
    },
    {
      title: 'Apartment number',
      dataIndex: 'apartmentNumber',
      key: 'apartmentNumber',
      width: '180px',
    },
    {
      title: 'Contact phone',
      dataIndex: 'contactPhone',
      key: 'contactPhone',
      width: '180px',
    },
    {
      title: 'Coordinates',
      dataIndex: 'coords',
      key: 'coords',
    },
    {
      title: 'Town',
      dataIndex: 'town',
      key: 'town',
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
    },
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
    },
  ]

  return (
    <Timeline>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        scroll={{ y: undefined, x: 1200 }}
        components={{
          body: {
            row: function RenderRow({ children, ...props }: any) {
              return (
                <TableBody className="ant-table-tbody">
                  <TimelineDot>{''}</TimelineDot>
                  <tr {...props}>{children}</tr>
                </TableBody>
              )
            },
            wrapper: function RenderWrapper({ children }: any) {
              return <>{children}</>
            },
          },
        }}
      />
    </Timeline>
  )
}

export default AddressHistory
