import { DefaultTheme } from 'styled-components'

export interface ThemeInterface extends DefaultTheme {
  cornFlower500: string
  midnight500: string
  peach500: string
  peach600: string
  apple700: string
  ghost200: string
  ghost500: string
}

const theme: ThemeInterface = {
  cornFlower500: '#6C65F7',
  midnight500: '#23005B',
  peach500: '#FF847C',
  peach600: '#D16D66',
  apple700: '#3AAB46',
  ghost200: '#F9FAFD',
  ghost500: '#C1C4CD',
}

export default theme
