import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { space } from '@quiqupltd/ui-kit.sizes'
import { ApiClient } from '@quiqupltd/quiqupjs'
import {
  Card as AntCard,
  Tooltip,
  Badge,
  Popover,
  Input,
  InputNumber as AntInput,
  Alert,
  Form,
  message,
  Button as AntButton,
  Divider,
} from 'antd'
import { RouteComponentProps } from 'react-router-dom'
import {
  CalendarOutlined,
  InteractionOutlined,
  EnvironmentOutlined,
  WalletOutlined,
  ReconciliationOutlined,
  UserOutlined,
  DashboardOutlined,
  CarOutlined,
} from '@ant-design/icons'
import uniqBy from 'lodash/uniqBy'
import {
  OrderWithScanned,
  OrderStatesLabels,
  OrderKindLabels,
  OrderInterface,
  ItemsWithScanned,
} from '../../types/order.type'
import { getPaymentDetail } from '../OrderDetail/OrderDetail'
import { hasLiveMission, getStateDot } from '../../utils/orders'
import { areAllItemsScanned } from './BulkActions'
import theme from '../../theme'
import { loadEnv } from '../../configEnv'

const Button = styled(AntButton)`
  width: 100%;
  margin-bottom: ${space(0.5)}px;
`
const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 35px;
`

const OrderCardStyled = styled(AntCard)<{ allItemsScanned: boolean }>`
  width: 250px;
  height: 100%;
  margin-right: ${space(1)}px;
  margin-top: ${space(1)}px;
  opacity: ${(p) => (p.allItemsScanned ? 1 : 0.7)};
`

const Items = styled.div`
  display: flex;
  justify-content: center;
`

const Dot = styled.div<{ scanned: boolean }>`
  width: 13px;
  height: 13px;
  border-radius: 24px;
  background: ${(p) => (p.scanned ? p.theme.apple700 : p.theme.ghost500)};
  &:nth-of-type(n + 2) {
    margin-left: 5px;
  }
`

const WarningText = styled.span`
  color: ${theme.peach500};
`

interface Props {
  recognizedOrders: OrderWithScanned[]
  updatedOrders: OrderWithScanned[]
  history: RouteComponentProps['history']
}

interface AddressProps {
  address1: string
  address2?: string
}

function Address(props: AddressProps): JSX.Element {
  return props.address1 ? (
    <>
      {props.address1.split(',').map((addressLine, index) => {
        if (index === 0) return <span key={addressLine}>{addressLine}</span>
        return <div key={addressLine}>{addressLine}</div>
      })}
      {props.address2 && <div>{props.address2}</div>}
    </>
  ) : (
    <WarningText>No Address!</WarningText>
  )
}

function ItemDot(props: { barcode: string; scanned: boolean }): JSX.Element {
  return (
    <Popover content={props.barcode}>
      <Dot scanned={props.scanned} data-testid={`${props.barcode}-${props.scanned ? 'not-scanned' : 'scanned'}`} />
    </Popover>
  )
}

interface OrderCard {
  id: OrderInterface['id']
  allItemsScanned: boolean
  stateDescription?: string
  stateColor: string
  serviceKind: OrderInterface['serviceKind']
  orderState: OrderInterface['state']
  address1: OrderInterface['destination']['address']['address1']
  address2: OrderInterface['destination']['address']['address2']
  contactName: OrderInterface['destination']['contactName']
  deliveryAttempts: OrderInterface['deliveryAttempts']
  items: ItemsWithScanned[]
  paymentDetail: string
  weightKg: OrderInterface['weightKg']
  history: RouteComponentProps['history']
}

const OrderCard = React.memo(function OrderCard({
  id,
  allItemsScanned,
  stateDescription,
  stateColor,
  serviceKind,
  orderState,
  address1,
  address2,
  paymentDetail,
  contactName,
  deliveryAttempts,
  items,
  weightKg,
}: OrderCard) {
  const [updateWeightErrorMessage, setUpdateWeightErrorMessage] = useState<string | undefined>()
  const [parcelWeight, setParcelWeight] = useState<number | string>(weightKg)
  const [updatedItems, setUpdatedItems] = useState<ItemsWithScanned[]>(() =>
    items.map((item) => {
      item.weight = 0
      return item
    })
  )
  const [form] = Form.useForm()

  async function handleItemWeightChange(
    value: number | string,
    itemName: string,
    parcelBarcode: string
  ): Promise<void> {
    const latestItems = updatedItems.map((item) => {
      if (item.name === itemName && item.parcelBarcode === parcelBarcode) {
        item.weight = value
      }
      return item
    })

    const updatedParcelWeight = latestItems.reduce<number | string>((acc, item) => {
      return Number(acc) + Number(item.weight || 0)
    }, 0)

    setUpdatedItems(latestItems)
    setParcelWeight(updatedParcelWeight)
  }

  async function handleUpdateWeight(): Promise<void> {
    form.validateFields().then(async () => {
      const env = await loadEnv()
      try {
        setUpdateWeightErrorMessage(undefined)
        await ApiClient.put({
          path: env.EX_CORE_API_URL + `/orders/${id}/weight`,
          data: { weight_kg: parcelWeight, items: updatedItems },
        })
        message.success(`Success! order's (id: ${id}) weight has been updated to ${parcelWeight} Kg`)
      } catch (error) {
        setUpdateWeightErrorMessage(`Failed to update the weight of the parcel ${id}`)
      }
    })
  }

  return (
    <>
      <OrderCardStyled title={id} data-testid={`order-card-${id}`} allItemsScanned={allItemsScanned}>
        <p data-testid={`order-state-${id}`}>
          <Tooltip placement="top" title={`State - ${stateDescription}`}>
            <InteractionOutlined /> {OrderStatesLabels[orderState]} <Badge color={stateColor} />
          </Tooltip>
        </p>
        {serviceKind && (
          <p data-testid={`order-kind-${id}`}>
            <Tooltip placement="top" title="Kind">
              <CalendarOutlined /> {OrderKindLabels[serviceKind]}
            </Tooltip>
          </p>
        )}

        <p data-testid={`destination-address-${id}`}>
          <Tooltip placement="top" title="Address">
            <EnvironmentOutlined /> <Address address1={address1} address2={address2 || undefined} />
          </Tooltip>
        </p>

        <p data-testid={`order-payment-${id}`}>
          <Tooltip placement="top" title="Payment type">
            <WalletOutlined /> {paymentDetail}
          </Tooltip>
        </p>

        {items && items.length > 0 ? (
          <p data-testid={`order-awb-${id}`}>
            <Tooltip placement="top" title="AWB">
              <ReconciliationOutlined /> {items[0].parcelBarcode}
            </Tooltip>
          </p>
        ) : null}

        <p data-testid={`destination-contact-${id}`}>
          <Tooltip placement="top" title="Contact name">
            <UserOutlined /> {contactName}
          </Tooltip>
        </p>

        <p data-testid={`order-delivery-attempts-${id}`}>
          <Tooltip placement="top" title="Delivery attempts">
            <CarOutlined /> {deliveryAttempts}
          </Tooltip>
        </p>

        <p data-testid={`total-weight`}>
          <Tooltip placement="top" title="Total Weight (Kg)">
            <DashboardOutlined /> {parcelWeight || 0} Kgs
          </Tooltip>
        </p>

        <Form
          labelWrap
          layout="vertical"
          onFinish={handleUpdateWeight}
          data-testid="submission_form"
          form={form}
          name="submission_form"
        >
          <Divider orientation="center">Item Weight (Kg)</Divider>
          <Input.Group>
            {updatedItems.map((item, idx) => (
              <Form.Item
                key={idx}
                name={idx + '_weight'}
                label={item.name}
                rules={[{ required: true, message: 'Weight is required' }]}
              >
                <AntInput
                  type="number"
                  style={{ width: '100%' }}
                  min="0"
                  max="200"
                  data-testid={`submission_form_${idx}_weight`}
                  onChange={(e) => handleItemWeightChange(e, item.name, item.parcelBarcode)}
                  value={item.weight}
                />
              </Form.Item>
            ))}
          </Input.Group>
          <Button key="submit" type="primary" onClick={form.submit} data-testid="update-weight-submit">
            Update Weight
          </Button>
        </Form>
        {updateWeightErrorMessage && <Alert message={updateWeightErrorMessage} type="error" showIcon />}

        <Items>
          {items.map((item) => (
            <ItemDot scanned={Boolean(item.scanned)} key={item.parcelBarcode} barcode={item.parcelBarcode} />
          ))}
        </Items>
      </OrderCardStyled>
    </>
  )
})

function getOrdersDetails(recognizedOrders: OrderWithScanned[], updatedOrders: OrderWithScanned[]): OrderWithScanned[] {
  return uniqBy(recognizedOrders.concat(updatedOrders), (order) => order.id)
}

// Tip: this component can impact on performance when there are a lot of orders to be shown
function BulkDetails(props: Props): JSX.Element {
  const orderDetails = useMemo(
    () => getOrdersDetails(props.recognizedOrders, props.updatedOrders),
    [props.recognizedOrders, props.updatedOrders]
  )

  return (
    <Container>
      {orderDetails.map((order) => {
        const isLive = hasLiveMission((order.mission && order.mission.state) || '')
        const stateDot = getStateDot(isLive, OrderStatesLabels[order.state])

        return (
          <OrderCard
            key={order.id}
            id={order.id}
            allItemsScanned={areAllItemsScanned([order])}
            stateDescription={stateDot.description}
            stateColor={stateDot.color}
            serviceKind={order.serviceKind}
            address1={order.destination.address.address1}
            address2={order.destination.address.address2}
            paymentDetail={getPaymentDetail(order)}
            orderState={order.state}
            contactName={order.destination.contactName}
            deliveryAttempts={order.deliveryAttempts}
            weightKg={order.weightKg || ''}
            items={order.items}
            history={props.history}
          />
        )
      })}
    </Container>
  )
}

export default React.memo(BulkDetails)
