/* eslint-disable import/prefer-default-export */
import { useReducer } from 'react'
import { Orders, ApiClient } from '@quiqupltd/quiqupjs'
import { OrderInterface, OrderStates } from '../types/order.type'
import { Depot } from '../types/history-order.type'
import { loadEnv } from '../configEnv'

// Interfaces
interface StateInterface {
  loading: boolean
  successMessage: string
  updatedOrders: OrderInterface[]
  failedOrders: string[]
  error: null | string
}

interface ActionInterface {
  updateOrders(addOrUpdateMission: string | null): void
}

interface HookInterface {
  updateState: StateInterface
  updateActions: ActionInterface
}

// Constants
enum actions {
  FETCH_INIT = 'FETCH_INIT',
  FETCH_SUCCESS = 'FETCH_SUCCESS',
  FETCH_FAILURE = 'FETCH_FAILURE',
}

const initialState: StateInterface = {
  loading: false,
  updatedOrders: [],
  failedOrders: [],
  successMessage: '',
  error: null,
}

// Helpers
export type MissionKinds =
  | 'Collection'
  | 'DeliveryFromClientLocation'
  | 'DeliveryFromQuiqupDepot'
  | 'Return'
  | 'ScanningCollection'
  | 'ReturnCollection'
  | 'Transfer'

export const MISSION_KINDS: { [key: string]: string } = {
  DeliveryFromClientLocation: '/orders/create_mission',
  Collection: '/orders/create_collection_mission',
  DeliveryFromQuiqupDepot: '/orders/create_delivery_mission',
  Return: '/orders/create_return_mission',
  ScanningCollection: '/orders/create_scanning_collection_mission',
  ReturnCollection: '/orders/create_return_collection_mission',
  Transfer: '/orders/create_transfer_mission',
}

// Reducer
type Action =
  | { type: actions.FETCH_INIT }
  | {
      type: actions.FETCH_SUCCESS
      response?: OrderInterface[]
      successMessage: string
    }
  | { type: actions.FETCH_FAILURE; error: string; ids: string[] }

function reducer(state: StateInterface, action: Action): StateInterface {
  switch (action.type) {
    case actions.FETCH_INIT:
      return { ...state, loading: true }
    case actions.FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        successMessage: action.successMessage,
      }
    }
    case actions.FETCH_FAILURE: {
      return {
        ...state,
        loading: false,
        failedOrders: [...action.ids, ...state.failedOrders],
        error: action.error,
      }
    }
    default:
      return state
  }
}

// Hook
export function useOrderUpdate(
  ids: string[],
  action: string | null,
  region?: Region.Code | null,
  depot?: Depot | null,
  newZoneText?: string | null,
  reason?: string | undefined,
  missionId?: string | null,
  uuids?: string[]
): HookInterface {
  const [state, dispatch] = useReducer(reducer, initialState)
  async function createOrUpdateMission(addOrUpdateMission: string | null): Promise<void> {
    if (!action) {
      return
    }

    try {
      if (addOrUpdateMission === 'add') {
        const env = await loadEnv()
        await ApiClient.put({
          path: env.EX_CORE_API_URL + `/api/missions/${missionId}/transfer`,
          data: {
            clientOrderIds: uuids,
            delivery_type: action,
            ...(depot ? { depot_id: depot?.id } : {}),
          },
        })
        dispatch({
          type: actions.FETCH_SUCCESS,
          successMessage: `Success! You have added ${uuids?.length} orders to mission ${missionId}`,
        })
      } else {
        const env = await loadEnv()
        await ApiClient.post({
          path: env.EX_CORE_API_URL + '/api/missions',
          data: {
            orderIds: ids,
            type: action,
            ...(depot ? { depot: { id: depot?.id } } : {}),
            ...(newZoneText ? { zone: newZoneText } : {}),
          },
        })
        dispatch({
          type: actions.FETCH_SUCCESS,
          successMessage: `Success! You have created 1 ${action} mission(s)`,
        })
      }
    } catch (error) {
      dispatch({ type: actions.FETCH_FAILURE, error: error.error, ids })
    }
  }

  async function updateState(): Promise<void> {
    try {
      let response: OrderInterface[] = []
      if (action === OrderStates.on_hold) {
        response = await ApiClient.put({
          route: '/orders/batch/set_on_hold',
          data: {
            orderIds: ids,
            ...(reason ? { onHoldReason: reason } : {}),
            ...(depot
              ? {
                  location: {
                    name: depot.name,
                    address: [depot.address1, depot.address2].join(', '),
                    coords: [depot.coordinates?.lng, depot.coordinates?.lat],
                    region: depot.region,
                  },
                }
              : {}),
          },
        })
      } else {
        response = await Orders.updateState({
          orderIds: ids,
          state: action,
          ...(depot
            ? {
                location: {
                  name: depot.name,
                  address: [depot.address1, depot.address2].join(', '),
                  coords: [depot.coordinates?.lng, depot.coordinates?.lat],
                  region: depot.region,
                },
              }
            : {}),
        })
      }
      if (response.length >= 1)
        dispatch({
          type: actions.FETCH_SUCCESS,
          response,
          successMessage: `Success! ${response.length} order(s) have been changed to ${action}`,
        })
      else dispatch({ type: actions.FETCH_FAILURE, error: `Can't update orders: [${ids}]`, ids })
    } catch (error) {
      dispatch({ type: actions.FETCH_FAILURE, error: error.error, ids })
    }
  }

  function updateOrders(addOrUpdateMission: string | null): void {
    dispatch({ type: actions.FETCH_INIT })
    if (ids && action) {
      if (MISSION_KINDS[action]) {
        createOrUpdateMission(addOrUpdateMission)
        return
      }

      updateState()
    }
  }

  return { updateState: state, updateActions: { updateOrders } }
}
