import React, { useState, useContext, useEffect } from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import Login from './components/Login'
import PrivateRoute from './components/PrivateRoute'
import Main from './components/Main'
import PublicRoute from './components/PublicRoute'
import OrdersTable from './components/OrdersTable'
import OrderDetail from './components/OrderDetail'
import BulkActions from './components/BulkActions'
import RoutePathnames from './types/routes.type'
import OrderCreation from './components/OrderCreation'
import { UserContext, ALLOWED_ORDER_CREATION_ROLES } from './context/user.context'

function Routes(): JSX.Element {
  const [hasUser, setHasUser] = useState(false)
  const userContext = useContext(UserContext)

  useEffect(() => {
    if (userContext.user) {
      setHasUser(true)
    }
  }, [userContext.user])

  return (
    <Router>
      <Switch>
        <PublicRoute path={RoutePathnames.LOGIN} component={Login} />
        <Main>
          {hasUser && (
            <Switch>
              <PrivateRoute path={RoutePathnames.ROOT} exact component={OrdersTable} />
              <PrivateRoute path={RoutePathnames.BULK_ACTIONS} exact component={BulkActions} />
              <PrivateRoute path={RoutePathnames.ORDER_DETAIL} exact component={OrderDetail} />
              <PrivateRoute
                path={RoutePathnames.CREATE_ORDER}
                exact
                component={OrderCreation}
                isAllowed={!!(userContext.user && userContext.user.roles.includes(ALLOWED_ORDER_CREATION_ROLES))}
              />
            </Switch>
          )}
        </Main>
      </Switch>
    </Router>
  )
}

export default Routes
