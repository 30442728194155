import React from 'react'
import * as Sentry from '@sentry/react'
import { Result } from 'antd'
import { ErrorBoundaryProps } from '@sentry/react/dist/errorboundary'

type Props = {
  title?: string
  subTitle?: string
  children: React.ReactNode
} & ErrorBoundaryProps

type FallBackProps = { title: string; subTitle: string }

const FallBack = ({ title, subTitle }: FallBackProps): JSX.Element => {
  return <Result status="500" title={title} subTitle={subTitle} />
}

export const PageErrorBoundary = ({
  children,
  title = 'Error',
  subTitle = 'Sorry, something went wrong.',
  ...otherProps
}: Props): JSX.Element => {
  return (
    <Sentry.ErrorBoundary fallback={<FallBack title={title} subTitle={subTitle} />} {...otherProps}>
      {children}
    </Sentry.ErrorBoundary>
  )
}
