import React from 'react'
import styled from 'styled-components'
import { format, parseISO } from 'date-fns'
import { Table as AntTable, Timeline } from 'antd'
import { CommentHistoryEventInterface } from '../../types/history-comment.type'
import { DATE_TIME_FORMAT } from '../../utils/time'

type Props = {
  history: CommentHistoryEventInterface[]
}

const Table = styled(AntTable)`
  td {
    border-bottom: none !important;
  }

  thead th {
    color: ${(p) => p.theme.midnight500} !important;
    background-color: transparent !important;
    border: none !important;
  }

  thead th:before {
    display: none;
  }

  tbody > tr {
    background-color: transparent;
  }

  .ant-table-tbody:last-of-type > .ant-timeline-item > .ant-timeline-item-tail {
    display: none;
  }
`
const TimelineDot = styled(Timeline.Item)`
  position: absolute !important;
  height: 100% !important;
  transform: translateY(calc(50% - 5px)) !important;
  padding-bottom: 0 !important;
  z-index: 999;
`

const TableBody = styled.tbody`
  position: relative !important;
`

function CommentHistory(props: Props): JSX.Element {
  const dataSource = props.history.map((event, index) => {
    return {
      date: event.insertedAt ? format(parseISO(event.insertedAt), DATE_TIME_FORMAT) : '',
      content: event.content,
      resourceId: event.resourceId,
      missionId: event.missionId,
      author: event.author,
    }
  })

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Content',
      dataIndex: 'content',
      key: 'content',
    },
    {
      title: 'Mission Id',
      dataIndex: 'missionId',
      key: 'missionId',
    },
    {
      title: 'Author',
      dataIndex: 'author',
      key: 'author',
    },
  ]

  return (
    <Timeline>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        scroll={{ y: undefined, x: 1200 }}
        components={{
          body: {
            row: function RenderRow({ children, ...props }: any) {
              return (
                <TableBody className="ant-table-tbody">
                  <TimelineDot>{''}</TimelineDot>
                  <tr {...props}>{children}</tr>
                </TableBody>
              )
            },
            wrapper: function RenderWrapper({ children }: any) {
              return <>{children}</>
            },
          },
        }}
      />
    </Timeline>
  )
}

export default CommentHistory
