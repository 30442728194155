/* eslint-disable import/prefer-default-export */
import { useReducer, useEffect } from 'react'
import { Orders } from '@quiqupltd/quiqupjs'
import { OrderHistoryEventInterface } from '../types/history-order.type'

// Interfaces
interface StateInterface {
  result: OrderHistoryEventInterface[] | []
  loading: boolean
  error: null | string
}

// Constants
enum actions {
  FETCH_INIT = 'FETCH_INIT',
  FETCH_SUCCESS = 'FETCH_SUCCESS',
  FETCH_FAILURE = 'FETCH_FAILURE',
}

const initialState: StateInterface = {
  result: [],
  error: null,
  loading: false,
}

// Helpers
async function fetchOrder(id: string): Promise<OrderHistoryEventInterface[]> {
  return Orders.getHistory(id)
}

// Reducer
type Action =
  | { type: actions.FETCH_INIT }
  | { type: actions.FETCH_SUCCESS; response: OrderHistoryEventInterface[] }
  | { type: actions.FETCH_FAILURE; error: string }

function reducer(state: StateInterface, action: Action): StateInterface {
  switch (action.type) {
    case actions.FETCH_INIT:
      return { ...state, loading: true }
    case actions.FETCH_SUCCESS: {
      return { ...state, result: action.response, loading: false }
    }
    case actions.FETCH_FAILURE: {
      return { ...state, loading: false, error: action.error }
    }
    default:
      return state
  }
}

// Hook
export function useOrderHistory(id: string): StateInterface {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    async function setOrder(): Promise<void> {
      try {
        dispatch({ type: actions.FETCH_INIT })
        const response = await fetchOrder(id)
        dispatch({ type: actions.FETCH_SUCCESS, response })
      } catch (error) {
        dispatch({ type: actions.FETCH_FAILURE, error })
      }
    }

    setOrder()
  }, [id])

  return state
}
