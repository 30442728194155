import React from 'react'
import { Form, Radio } from 'antd'
import styled from 'styled-components'

interface Option {
  value: string | string[]
  label: string
}

interface Props {
  label: string
  identifier: string
  options: Option[]
  required?: boolean
  disabled?: boolean
}

const StyledFormItem = styled(Form.Item)`
  margin-left: 20px;
`

function RadioField(props: Props): JSX.Element {
  return (
    <StyledFormItem
      label={props.label}
      colon={false}
      name={props.identifier}
      rules={[
        {
          required: props.disabled ? false : props.required,
          message: `${props.label} is required`,
        },
      ]}
    >
      <Radio.Group disabled={props.disabled}>
        {props.options.map((option) => (
          <Radio key={option.label} value={option.value}>
            {option.label}
          </Radio>
        ))}
      </Radio.Group>
    </StyledFormItem>
  )
}

export default RadioField
