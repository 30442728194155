import React from 'react'
import { Button } from 'antd'
import { StaticContext } from 'react-router'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import Routes from '../../../types/routes.type'
import { ArrowLeftOutlined } from '@ant-design/icons'

const StyledButton = styled(Button)`
  position: absolute !important;
  left: 30px;
  top: 10px;
  background-color: transparent !important;
`

interface Props
  extends RouteComponentProps<
    { [key: string]: string | undefined },
    StaticContext,
    {
      from: string
    }
  > {
  paths: string[]
}

function BackButton(props: Props): JSX.Element {
  const handleBackButtonPressed = (): void => {
    // If a back history is available and a state is provided, then just go back
    const shouldGoBack =
      props.location && props.location.state && props.paths.indexOf(props.location.state.from) > -1 && props.history

    if (shouldGoBack) props.history.goBack()
    else if (
      // Otherwise, go back to pre-set pages
      props.location &&
      props.history &&
      props.location.pathname.includes(Routes.CREATE_ORDER)
    ) {
      props.history.push({
        pathname: Routes.ROOT,
      })
    }
  }

  return (
    <>
      {props.history && props.location ? (
        <StyledButton type="link" size="large" onClick={handleBackButtonPressed}>
          <ArrowLeftOutlined /> Back
        </StyledButton>
      ) : null}
    </>
  )
}

export default withRouter(BackButton)
