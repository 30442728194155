import { useReducer, useEffect } from 'react'
import { ApiClient } from '@quiqupltd/quiqupjs'
import { loadEnv } from '../configEnv'

interface StateInterface {
  results: string[]
  error: null | string
  loading: boolean
}

const initialState: StateInterface = {
  results: [],
  error: null,
  loading: false,
}

enum actions {
  FETCH_INIT = 'FETCH_INIT',
  FETCH_SUCCESS = 'FETCH_SUCCESS',
  FETCH_FAILURE = 'FETCH_FAILURE',
}

type ActionInit = {
  type: actions.FETCH_INIT
}

type ActionSuccess = {
  type: actions.FETCH_SUCCESS
  response: string[]
}

type ActionFailure = {
  type: actions.FETCH_FAILURE
  error: string
}

type Action = ActionInit | ActionSuccess | ActionFailure

async function fetchZones(): Promise<string[]> {
  const env = await loadEnv()

  const apiZones = await ApiClient.get({
    path: env.EX_CORE_API_URL + '/api/zones',
  })

  return apiZones.data
}

function reducer(state: StateInterface, action: Action): StateInterface {
  switch (action.type) {
    case actions.FETCH_INIT:
      return { ...state, loading: true }
    case actions.FETCH_SUCCESS: {
      const results = action.response
      return {
        results,
        error: null,
        loading: false,
      }
    }
    case actions.FETCH_FAILURE: {
      return { ...state, loading: false, error: action.error }
    }
    default:
      return state
  }
}

export function useZones(): StateInterface {
  const [state, dispatch] = useReducer(reducer, initialState)
  useEffect(() => {
    async function setZones(): Promise<void> {
      try {
        dispatch({ type: actions.FETCH_INIT })
        const response = await fetchZones()
        dispatch({ type: actions.FETCH_SUCCESS, response })
      } catch (error) {
        dispatch({ type: actions.FETCH_FAILURE, error })
      }
    }
    setZones()
  }, [])
  return state
}
