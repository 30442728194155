import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Button, Select } from 'antd'

export const Container = styled.div`
  padding: 0 12px;

  td {
    word-break: break-word;
    border-bottom: none !important;
  }

  thead th {
    color: ${(p) => p.theme.midnight500} !important;
  }

  tbody > tr:nth-of-type(2n) {
    background-color: ${(p) => p.theme.ghost200};
  }
`

export const FiltersButton = styled(Button)``

export const BulkActionsButton = styled(Button)``

export const Total = styled.strong``

export const linkStyle = `
  color: inherit;
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
  }
`

export const Id = styled(Link)`
  ${linkStyle}
`

export const ExternalId = styled.a`
  ${linkStyle}
`

export const State = styled.div`
  display: flex;
  align-items: center;

  & > .state-text {
    width: max-content;
    margin-right: 3px;
  }
`
export const Kind = styled.div`
  display: flex;
  align-items: center;
`

export const ActionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0 0 0;
  flex-flow: wrap;

  > :nth-last-child(n + 2) {
    margin-right: 8px !important;
  }

  &:nth-of-type(n + 2) {
    padding: 8px 0;
  }
`

export const ActionRowSection = styled.div<{ end?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-basis: auto;
  min-width: fit-content;
  flex-flow: wrap;
  justify-content: ${(props) => (props.end === 'true' ? 'flex-end' : 'flex-start')};
  flex-grow: 1;

  > :nth-last-child(n + 2) {
    margin-right: 8px !important;
  }

  &:nth-child(n + 2) {
    margin-top: 6px !important;
  }
`

export const RegionSelect = styled(Select)`
  width: 200px;
`

export const AddressName = styled.span`
  font-weight: bold;
  display: block;
`
export const AddressDescription = styled.span``
