import React from 'react'
import { Form, Input } from 'antd'
import styled from 'styled-components'

interface Props {
  label: string
  identifier: string
  required?: boolean
  placeHolder?: string
  disabled?: boolean
}

const StyledFormItem = styled(Form.Item)`
  flex-grow: 1;
  margin-left: 20px;
`

function TextField(props: Props): JSX.Element {
  return (
    <StyledFormItem
      label={props.label}
      colon={false}
      name={props.identifier}
      rules={[
        {
          required: props.disabled ? false : props.required,
          message: `${props.label} is required`,
        },
      ]}
    >
      <Input
        placeholder={props.placeHolder ? props.placeHolder : props.label}
        data-testid={`${props.label}-input`}
        disabled={props.disabled}
      />
    </StyledFormItem>
  )
}

export default TextField
