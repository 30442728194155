import React, { useEffect } from 'react'
import styled from 'styled-components'
import { space } from '@quiqupltd/ui-kit.sizes'
import { Modal as AntModal, Button, Form, Input, Alert } from 'antd'
import { Address, UpdateLocationAddressFormValues } from '../../types/order.type'

const Modal = styled(AntModal)`
  width: ${space(80)}px !important;
`

interface Props {
  isOpen: boolean
  onClose: () => void
  onSubmit: (
    coordinates: UpdateLocationAddressFormValues & {
      coreApiLocationId: Address['id']
      waypointType: string | undefined
      pinCheckType: string
    }
  ) => void
  address: Address
  isLoading: boolean
  errorMessage?: string
  waypointType: string | undefined
  pinCheckType: string
}

function mapAddress(address: Address): UpdateLocationAddressFormValues {
  return {
    address1: address.address1,
    address2: address.address2,
    apartmentNumber: address.apartmentNumber,
    town: address.town,
    country: address.country,
    coordinates: `${address.coordinates.lat}, ${address.coordinates.lng}`,
  }
}

function UpdateLocationModel(props: Props): JSX.Element {
  const [form] = Form.useForm()
  useEffect(() => {
    form.setFieldsValue(mapAddress(props.address))
  }, [form, props.address])

  function handleClose(): void {
    form.resetFields()
    props.onClose()
  }

  function handleSubmit(values: UpdateLocationAddressFormValues): void {
    props.onSubmit({
      ...values,
      coreApiLocationId: props.address.id,
      waypointType: props.waypointType,
      pinCheckType: props.pinCheckType,
    })
    form.resetFields()
  }

  return (
    <Modal
      title="Update Location"
      visible={props.isOpen}
      footer={[
        <Button key="back" onClick={handleClose} disabled={props.isLoading}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={form.submit}
          loading={props.isLoading}
          data-testid="update-address-submit"
        >
          OK
        </Button>,
      ]}
      onCancel={handleClose}
    >
      <div data-testid="update-address-modal">
        <Form
          layout="horizontal"
          onFinish={handleSubmit}
          data-testid="submission_form"
          name="submission_form"
          form={form}
          initialValues={mapAddress(props.address)}
        >
          <Form.Item
            label="Address Line 1"
            colon={false}
            name="address1"
            rules={[
              {
                required: true,
                message: 'address line 1 is required',
              },
            ]}
          >
            <Input placeholder="Address Line 1" data-testid={'address1-input'} />
          </Form.Item>

          <Form.Item label="Address Line 2 (optional)" colon={false} name="address2">
            <Input placeholder="Address Line 2" data-testid={'address2-input'} />
          </Form.Item>

          <Form.Item label="Apartment number (optional)" colon={false} name="apartmentNumber">
            <Input placeholder="Apartment number" data-testid={'apartment-number-input'} />
          </Form.Item>

          <Form.Item
            label="Town"
            colon={false}
            name="town"
            rules={[
              {
                required: true,
                message: 'town is required',
              },
            ]}
          >
            <Input placeholder="Dubai" data-testid={'town-input'} />
          </Form.Item>

          <Form.Item
            label="Country"
            colon={false}
            name="country"
            rules={[
              {
                required: true,
                message: 'country is required',
              },
            ]}
          >
            <Input placeholder="UAE" data-testid={'country-input'} />
          </Form.Item>

          <Form.Item
            label="Coordinates"
            colon={false}
            name="coordinates"
            rules={[
              {
                required: true,
                message: 'coordinates are required',
              },
            ]}
          >
            <Input placeholder="Latittude, Longitude" data-testid={'coordinates-input'} />
          </Form.Item>
        </Form>
      </div>

      {props.errorMessage && <Alert message={props.errorMessage} type="error" showIcon />}
    </Modal>
  )
}

export default UpdateLocationModel
