import React from 'react'
import { Button, Badge } from 'antd'
import { FiltersButton } from './StyledElements'
import { AddressChecked, OrderKinds, OrderStates } from '../../types/order.type'
import styled from 'styled-components'
import uniq from 'lodash/uniq'
import { FilterOutlined } from '@ant-design/icons'
import moment from 'moment'

interface Props {
  onClickQuickStateFilter: (value: OrderStates[], action: 'select' | 'deselect') => void
  openFiltersModal: () => void
  disabled: boolean
  isAddressChecked: AddressChecked
  activeStateFilters: OrderStates[]
  onClickQuickAddressCheckedFilter: (value: boolean) => void
  onClickQuickDeliveryBeforeFilter: (value: string, action: 'select' | 'deselect') => void
  activeKindFilters: OrderKinds[]
  activeDeliveryBeforeDates: string[]
  onClearFilters: () => void
  statesCount: Record<OrderStates, number>
}

type QuickStateFilterList = Array<{ label: string; values: OrderStates[] }>

export const QUICK_STATE_FILTERS: QuickStateFilterList = [
  {
    label: 'To be received',
    values: [OrderStates.ready_for_collection, OrderStates.collected],
  },
  {
    label: 'With driver for delivery',
    values: [OrderStates.out_for_delivery, OrderStates.delivery_failed],
  },
  {
    label: 'At depot',
    values: [OrderStates.at_depot, OrderStates.received_at_depot, OrderStates.scheduled, OrderStates.on_hold],
  },
  {
    label: 'To be returned to client',
    values: [OrderStates.return_to_origin],
  },
  {
    label: 'Completed',
    values: [OrderStates.delivery_complete, OrderStates.returned_to_origin, OrderStates.cancelled],
  },
  {
    label: 'Problematic',
    values: [OrderStates.delivery_failed, OrderStates.collected],
  },
]

export const QUICK_ADDRESS_CHECKED_FILTERS = [
  {
    label: 'Checked Address',
    value: true,
  },
  {
    label: 'Unchecked Address',
    value: false,
  },
]

export const QUICK_DELIVERY_BEFORE_FILTER = [
  {
    label: 'Today',
    value: moment().format('DD-MM-YYYY'),
  },
  {
    label: 'Tomorrow',
    value: moment().add(1, 'days').format('DD-MM-YYYY'),
  },
]

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > :nth-last-child(n + 3) {
    margin-right: 8px !important;
  }

  > :nth-last-child(n) {
    margin-top: 6px !important;
  }
`

function FiltersButtons({
  openFiltersModal,
  disabled,
  isAddressChecked,
  onClickQuickStateFilter,
  onClickQuickAddressCheckedFilter,
  onClickQuickDeliveryBeforeFilter,
  activeStateFilters,
  activeKindFilters,
  activeDeliveryBeforeDates,
  onClearFilters,
  statesCount,
}: Props): JSX.Element {
  function getQuickStatesFilterCount(values: OrderStates[]): number {
    return values.reduce((count, filter) => (statesCount[filter] ?? 0) + count, 0)
  }
  return (
    <Container>
      {QUICK_STATE_FILTERS.map((filter) => {
        const active = filter.values.every((value: OrderStates) => activeStateFilters.includes(value))
        return (
          <Button
            type={active ? 'primary' : 'default'}
            key={filter.values.join('-')}
            disabled={disabled}
            onClick={() => onClickQuickStateFilter(filter.values, active ? 'deselect' : 'select')}
            data-testid={`quick-filter-button-${filter.values.join('-')}`}
            data-testactive={active}
          >
            {`${filter.label} (${getQuickStatesFilterCount(filter.values)})`}
          </Button>
        )
      })}
      {QUICK_ADDRESS_CHECKED_FILTERS.map((filter) => {
        const active = filter.value === isAddressChecked
        return (
          <Button
            type={active ? 'primary' : 'default'}
            key={'is-location-checked' + filter.value}
            onClick={() => onClickQuickAddressCheckedFilter(filter.value)}
            data-testid={`quick-filter-button-${filter.value}`}
            data-testactive={active}
          >
            {`${filter.label}`}
          </Button>
        )
      })}
      {QUICK_DELIVERY_BEFORE_FILTER.map((filter) => {
        const active = activeDeliveryBeforeDates?.includes(filter.value)
        return (
          <Button
            type={active ? 'primary' : 'default'}
            key={filter.label.toLowerCase()}
            onClick={() => onClickQuickDeliveryBeforeFilter(filter.value, active ? 'deselect' : 'select')}
            data-testid={`quick-filter-button-${filter.label.toLowerCase()}`}
            data-testactive={active}
          >
            {`${filter.label}`}
          </Button>
        )
      })}
      <Badge count={uniq([...activeStateFilters, ...activeKindFilters]).length}>
        <FiltersButton disabled={disabled} onClick={openFiltersModal} data-testid="filters-button">
          <FilterOutlined /> Filters
        </FiltersButton>
      </Badge>
      <Button type="link" onClick={onClearFilters}>
        Clear filters
      </Button>
    </Container>
  )
}

export default FiltersButtons
