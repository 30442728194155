import React from 'react'
import { space } from '@quiqupltd/ui-kit.sizes'
import { Button } from 'antd'
import styled from 'styled-components'

const Container = styled.div`
  padding-top: 5px;
  display: flex;
  align-items: center;

  > :nth-last-child(n + 2) {
    margin-right: ${space(1)}px !important;
  }
`

const MissionId = ({
  id,
  cancelable,
  separateOrder,
  loading,
}: {
  id: string
  cancelable: boolean
  separateOrder: any
  loading: boolean
}): JSX.Element => {
  return (
    <Container>
      <div data-testid="mission-id">{id}</div>
      {cancelable ? (
        <Button danger={true} onClick={separateOrder} loading={loading} disabled={loading} data-testid="cancel-button">
          Remove from the mission
        </Button>
      ) : null}
    </Container>
  )
}

export default MissionId
