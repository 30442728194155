import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { Auth } from '@quiqupltd/quiqupjs'
import Routes from '../../types/routes.type'

interface Props {
  component: React.ReactType
  path?: string
  exact?: boolean
}

function PublicRoute({ component: Component, ...rest }: Props): JSX.Element {
  return (
    <Route
      {...rest}
      render={(routeProps) =>
        Auth.hasToken() ? (
          <Redirect
            to={{
              pathname: Routes.ROOT,
              state: { from: routeProps.location },
            }}
          />
        ) : (
          <Component {...routeProps} />
        )
      }
    />
  )
}

PublicRoute.defaultProps = {
  requiresLoggedOut: false,
}

export default PublicRoute
