import { useReducer, useEffect, useCallback } from 'react'
import { ApiClient } from '@quiqupltd/quiqupjs'
import { OrderInterface } from '../types/order.type'
import { loadEnv } from '../configEnv'

// Interfaces

interface StateInterface {
  result: OrderInterface | null
  loading: boolean
  error: null | string
}
interface HookInterface {
  order: StateInterface
  refreshOrder: () => Promise<void>
}

interface OrdersApiInterface {
  order: OrderInterface
}

// Constants
enum actions {
  FETCH_INIT = 'FETCH_INIT',
  FETCH_SUCCESS = 'FETCH_SUCCESS',
  FETCH_FAILURE = 'FETCH_FAILURE',
}

type ActionInit = {
  type: 'FETCH_INIT'
}

type ActionSuccess = {
  type: 'FETCH_SUCCESS'
  response: {
    order: OrderInterface
  }
}

type ActionFailure = {
  type: 'FETCH_FAILURE'
  error: string
}

type Action = ActionInit | ActionSuccess | ActionFailure

const initialState: StateInterface = {
  result: null,
  error: null,
  loading: false,
}

// Helpers
async function fetchOrder(id: string): Promise<OrdersApiInterface> {
  const env = await loadEnv()
  const order = await ApiClient.get({
    path: env.EX_CORE_API_URL + `/orders/${id}`,
  })

  return order
}

// Reducer
function reducer(state: StateInterface, action: Action): StateInterface {
  switch (action.type) {
    case actions.FETCH_INIT:
      return { ...state, loading: true }
    case actions.FETCH_SUCCESS: {
      const { order } = action.response
      return { ...state, result: order, loading: false }
    }
    case actions.FETCH_FAILURE: {
      return { ...state, loading: false, error: action.error }
    }
    default:
      return state
  }
}

// Hook
export function useOrderDetail(id: string): HookInterface {
  const [state, dispatch] = useReducer(reducer, initialState)

  const setOrder = useCallback(async () => {
    try {
      dispatch({ type: actions.FETCH_INIT })
      const response = await fetchOrder(id)

      dispatch({ type: actions.FETCH_SUCCESS, response })
    } catch (error) {
      dispatch({ type: actions.FETCH_FAILURE, error })
    }
  }, [id])

  useEffect(() => {
    setOrder()
  }, [id, setOrder])

  return {
    order: state,
    refreshOrder: setOrder,
  }
}
