import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Select as AntSelect, Button, Input as AntInput, Tooltip } from 'antd'
import { allowedSearchType } from '../../hooks/orders'

const { Option } = AntSelect

interface SearchFilterProps {
  onSearch: (type: allowedSearchType | '', text: string) => void
  isLoading: boolean
  shouldReset: boolean
}

const Container = styled.form`
  display: flex;

  > :nth-last-child(n + 2) {
    margin-right: 15px !important;
  }
`

const Select = styled(AntSelect)`
  width: 144px !important;
`

const Input = styled(AntInput)`
  width: 257px;
`

function SearchFilter(props: SearchFilterProps): JSX.Element {
  const [searchType, setSearchType] = useState<allowedSearchType | ''>('')
  const [searchText, setSearchText] = useState('')
  const isInvalidText = searchText.length < 3

  function handleSearchTypeChange(value: unknown) {
    setSearchType(value as allowedSearchType)
  }

  function handleSearchTextChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchText(event.target.value)
  }

  function handleSearch(event: React.MouseEvent) {
    event.preventDefault()
    props.onSearch(searchType, searchText)
  }

  function handleReset() {
    setSearchType('')
    setSearchText('')
    props.onSearch('', '')
  }

  useEffect(() => {
    if (props.shouldReset) {
      setSearchType('')
      setSearchText('')
    }
  }, [props.shouldReset])

  return (
    <Container>
      <Select
        placeholder="Search for..."
        onChange={handleSearchTypeChange}
        value={searchType || undefined}
        data-testid="search-type"
      >
        <Option value="customer_name" data-testid="customer_name">
          Receiver name
        </Option>
        <Option value="customer_phone" data-testid="customer_phone">
          Receiver phone
        </Option>
        <Option value="client" data-testid="client">
          Client name
        </Option>
        <Option value="shipment_number" data-testid="shipment_number">
          Shipment / AWB
        </Option>
        <Option value="order_id" data-testid="order_id">
          Order reference
        </Option>
        <Option value="partner_order_id" data-testid="partner_order_id">
          Partner Order ID
        </Option>
      </Select>
      <Input placeholder="Search term..." onChange={handleSearchTextChange} disabled={!searchType} value={searchText} />
      {isInvalidText ? (
        <Tooltip placement="top" title="Text must have at least 3 characters">
          <Button
            htmlType="submit"
            type="primary"
            onClick={handleSearch}
            disabled={!searchType || props.isLoading || isInvalidText}
            loading={props.isLoading}
            data-testid="search-filter-button"
          >
            Search
          </Button>
        </Tooltip>
      ) : (
        <Button
          htmlType="submit"
          type="primary"
          onClick={handleSearch}
          disabled={!searchType || props.isLoading || searchText.length < 3}
          loading={props.isLoading}
          data-testid="search-filter-button"
        >
          Search
        </Button>
      )}

      {searchText && (
        <Button type="link" onClick={handleReset} disabled={props.isLoading}>
          Reset
        </Button>
      )}
    </Container>
  )
}

export default SearchFilter
