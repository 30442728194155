import { Enum } from '@martin_hotell/rex-tils'

type MissionStateLabels = { [key in MissionStates]: string }
export const MissionStatesLabels: MissionStateLabels = {
  requested: 'Requested',
  queued: 'Queued',
  received: 'Received',
  ordering: 'Ordering',
  pending_assignment: 'Pending assignment',
  assigned: 'Assigned',
  pickup_started: 'Pickup started',
  pickup_arrived: 'Pickup arrived',
  items_purchased: 'Items purchased',
  drop_started: 'Drop started',
  drop_arrived: 'Drop arrived',
  in_progress: 'In progress',
  complete: 'Complete',
  completed: 'Completed', // new state
  created: 'Created', // new state
  pending: 'Requested',
  cancelled: 'Cancelled',
}

export const MissionStates = Enum(
  'requested',
  'queued',
  'received',
  'ordering',
  'pending_assignment',
  'assigned',
  'pickup_started',
  'pickup_arrived',
  'items_purchased',
  'drop_started',
  'drop_arrived',
  'in_progress',
  'complete',
  'completed', // new state
  'created', // new state
  'pending',
  'cancelled'
)

export type MissionStates = Enum<typeof MissionStates>
