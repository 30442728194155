import theme from '../theme'
import { OrderStatesLabels } from '../types/order.type'

/* eslint-disable import/prefer-default-export */
export const MISSION_STATE_REQUESTED = 'requested'
export const MISSION_STATE_RECEIVED = 'received'
export const MISSION_STATE_ORDERING = 'ordering'
export const MISSION_STATE_PENDING_ASSIGNMENT = 'pending_assignment'
export const MISSION_STATE_ASSIGNED = 'assigned'
export const MISSION_STATE_CREATED = 'created'
export const MISSION_STATE_QUEUED = 'queued'
export const MISSION_STATE_IN_PROGRESS = 'in_progress'
export const MISSION_STATE_PICKUP_STARTED = 'pickup_started'
export const MISSION_STATE_PICKUP_ARRIVED = 'pickup_arrived'
export const MISSION_STATE_ITEMS_PURCHASED = 'items_purchased'
export const MISSION_STATE_DROP_STARTED = 'drop_started'
export const MISSION_STATE_DROP_ARRIVED = 'drop_arrived'
export const MISSION_STATE_CANCELLED = 'cancelled'
export const MISSION_STATE_COMPLETE = 'complete'

export const MISSION_STATE_COLLECTED = 'collected'
export const MISSION_STATE_DELIVERY_FAILED = 'delivery_failed'

export const hasLiveMission = (state: string): boolean => {
  switch (state) {
    case MISSION_STATE_REQUESTED:
    case MISSION_STATE_CREATED:
    case MISSION_STATE_RECEIVED:
    case MISSION_STATE_ORDERING:
    case MISSION_STATE_PENDING_ASSIGNMENT:
    case MISSION_STATE_IN_PROGRESS:
    case MISSION_STATE_ASSIGNED:
    case MISSION_STATE_QUEUED:
    case MISSION_STATE_PICKUP_STARTED:
    case MISSION_STATE_PICKUP_ARRIVED:
    case MISSION_STATE_ITEMS_PURCHASED:
    case MISSION_STATE_DROP_STARTED:
    case MISSION_STATE_DROP_ARRIVED:
      return true
    default:
      return false
  }
}

export function getStateDot(isLive: boolean, state: string): { color: string; description?: string } {
  if (isLive) return { color: theme.apple700, description: 'has a live mission' }
  if (!isLive && (state === OrderStatesLabels.collected || state === OrderStatesLabels.delivery_failed)) {
    return { color: theme.peach500, description: 'no live mission' }
  }

  return { color: '' }
}
