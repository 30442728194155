import React, { useContext, useEffect, useCallback } from 'react'
import { Partner, Auth, EventManager } from '@quiqupltd/quiqupjs'
import { RouteComponentProps, withRouter, Link } from 'react-router-dom'
import styled from 'styled-components'
import { Layout, Button } from 'antd'
import { ReactComponent as Logo } from '../../assets/banner-logo.svg'
import { UserContext, ALLOWED_ORDER_CREATION_ROLES } from '../../context/user.context'
import UserInterface from '../../types/user.type'
import Routes from '../../types/routes.type'

const Nav = styled(Layout.Header)`
  background: rgb(255, 132, 124) !important;
  display: flex;
  align-items: center;

  & > a {
    margin-right: auto;
    display: flex;
  }
`

const NavButton = styled(Button)`
  :nth-of-type(n + 2) {
    margin-left: 20px;
  }
`

const Content = styled(Layout.Content)`
  min-height: calc(100vh - 64px) !important;
`

interface Props extends RouteComponentProps {
  children?: React.ReactNode
}

function Main(props: Props): JSX.Element {
  const userContext = useContext(UserContext)

  function handleSubmitButtonClick(): void {
    props.history.push(Routes.CREATE_ORDER)
  }

  function getUserToDisplay(user: UserInterface | null): string {
    if (user && user.firstname && user.lastname) {
      return `${user.firstname} ${user.lastname[0]}`
    }

    return ''
  }

  const handleLogout = useCallback(() => {
    Auth.removeToken()
    userContext.removeUser()
    props.history.push(Routes.LOGIN)
  }, [userContext, props.history])

  useEffect(() => {
    async function fetchUser(): Promise<void> {
      const fetchedUser: UserInterface = await Partner.user.get()
      userContext.setUser(fetchedUser)
    }

    /* user is fetched after authentication,
       if user has just logged in, then do not fetch again
    */
    if (!userContext.user) {
      fetchUser()
    }
  }, [userContext])

  useEffect(() => {
    EventManager.addEventListener('onUnauthenticated', handleLogout)

    return () => EventManager.removeEventListener('onUnauthenticated', handleLogout)
  }, [handleLogout])

  return (
    <Layout>
      <Nav>
        <Link to="/" data-testid="logo">
          <Logo />
        </Link>

        {userContext.user && userContext.user.roles.includes(ALLOWED_ORDER_CREATION_ROLES) ? (
          <NavButton type="primary" onClick={handleSubmitButtonClick}>
            Submit order
          </NavButton>
        ) : null}
        <NavButton type="primary" danger onClick={handleLogout} data-testid="logout">
          {getUserToDisplay(userContext.user)} | Logout
        </NavButton>
      </Nav>
      <Content>{props.children}</Content>
    </Layout>
  )
}

export default withRouter(Main)
