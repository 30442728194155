import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { space } from '@quiqupltd/ui-kit.sizes'
import { Modal as AntModal, Col, Row, Checkbox, Button } from 'antd'
import {
  FilterableOrderStates,
  OrderStatesLabels,
  OrderKindLabels,
  FilterableOrderKinds,
  OrderKinds,
  OrderStates,
} from '../../types/order.type'

const CheckboxGroup = styled(Checkbox.Group)`
  width: 100%;
`

const Subtitle = styled.h3`
  color: ${(p) => p.theme.midnight500};
`

const KindsSubtitle = styled.h3`
  color: ${(p) => p.theme.midnight500};
  margin-top: 14px;
`

const Modal = styled(AntModal)`
  width: ${space(80)}px !important;
`

interface Props {
  isOpen: boolean
  onClose: () => void
  onSubmit: (states: Order.State[], kinds: Order.ServiceKind[]) => void
  orderStates: OrderStates[]
  orderKinds: OrderKinds[]
  statesCount: { [key: string]: number }
  kindsCount: { [key: string]: number }
  resetStateFilters: () => void
}

function FiltersModal(props: Props): JSX.Element {
  const [selectedStates, setSelectedStates] = useState(props.orderStates)
  const [selectedKinds, setSelectedKinds] = useState(props.orderKinds)

  function handleClose(): void {
    setSelectedStates(props.orderStates)
    setSelectedKinds(props.orderKinds)
    props.onClose()
  }

  function handleSelectedStatesChange(states: unknown): void {
    setSelectedStates(states as Order.State[])
  }
  function handleSelectedKindsChange(kinds: unknown): void {
    setSelectedKinds(kinds as Order.ServiceKind[])
  }

  function handleSubmit(): void {
    props.onSubmit(selectedStates, selectedKinds)
  }

  function resetFilters(): void {
    props.resetStateFilters()
    props.onClose()
  }

  useEffect(() => {
    setSelectedStates(props.orderStates)
  }, [props.orderStates])

  useEffect(() => {
    setSelectedKinds(props.orderKinds)
  }, [props.orderKinds])

  return (
    <Modal
      title="Filters"
      visible={props.isOpen}
      footer={[
        <Button key="back" onClick={handleClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit}>
          OK
        </Button>,
        <Button key="link" type="link" onClick={resetFilters}>
          Clear
        </Button>,
      ]}
      onCancel={handleClose}
    >
      <Subtitle>States</Subtitle>
      <div data-testid="states-selection">
        <CheckboxGroup onChange={handleSelectedStatesChange} value={selectedStates}>
          <Row>
            {(Object.keys(FilterableOrderStates) as Order.State[]).map((state) => (
              <Col span={8} key={state}>
                <Checkbox value={state} data-testid={state}>
                  {OrderStatesLabels[state]} <strong>({props.statesCount[state] || '0'})</strong>
                </Checkbox>
              </Col>
            ))}
          </Row>
        </CheckboxGroup>
      </div>
      <KindsSubtitle>Kinds</KindsSubtitle>
      <div data-testid="kinds-selection">
        <CheckboxGroup onChange={handleSelectedKindsChange} value={selectedKinds}>
          <Row>
            {(Object.keys(FilterableOrderKinds) as Order.ServiceKind[]).map((kind) => (
              <Col span={8} key={kind}>
                <Checkbox value={kind} data-testid={kind}>
                  {OrderKindLabels[kind]} <strong>({props.kindsCount[kind] || '0'})</strong>
                </Checkbox>
              </Col>
            ))}
          </Row>
        </CheckboxGroup>
      </div>
    </Modal>
  )
}

export default FiltersModal
