import React from 'react'
import styled from 'styled-components'
import { CopyOutlined } from '@ant-design/icons'
import { notification } from 'antd'

export const NOTIFICATION_TITLE = 'Copied to clipboard!'
const NOTIFICATION_DURATION = 3 // In seconds

const ClipboardIcon = styled(CopyOutlined)`
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: opacity 0.3s;
  &:hover {
    opacity: 0.7;
  }
`
interface CopyToClipboardProps {
  content?: string
}

const CopyToClipboard = (props: CopyToClipboardProps): JSX.Element => {
  const { content } = props

  const copyContent = (): void => {
    if (content) {
      navigator.clipboard.writeText(content)

      notification.open({
        message: NOTIFICATION_TITLE,
        description: content,
        duration: NOTIFICATION_DURATION,
      })
    }
  }

  return (
    <div>
      <ClipboardIcon onClick={copyContent} data-testid="copy-to-clipboard-button" />
    </div>
  )
}

export default React.memo(CopyToClipboard)
